<script src="live.component.ts"></script>
<div class="live-view-container">
    <div class="lv-map-sidebar" style="background: #eee">
        <div style="padding: 14px">
            <div class="searchToolbar">
                <div class="searchContainer">
                    <input
                        type="text"
                        placeholder="Search"
                        [(ngModel)]="filter.searchTerm"
                        (change)="onSeachOrFilterChangeCallback()"
                        i18n-placeholder
                    />
                    <button (click)="onSeachOrFilterChangeCallback()">
                        <mat-icon
                            class="searchicon"
                            aria-hidden="false"
                            aria-label="search"
                            i18n
                            >search</mat-icon
                        >
                    </button>
                </div>

                <div>
                    <button
                        mat-mini-fab
                        color="primary"
                        aria-label="Device list options"
                        (click)="openFilterDialog()"
                        style="
                            border-radius: 4px;
                            background-color: #2577da;
                            margin-left: 5px;
                        "
                        matTooltip="Device list options"
                    >
                        <mat-icon>filter_list</mat-icon>
                    </button>
                    <button
                        mat-mini-fab
                        color="primary"
                        aria-label="Reset map zoom"
                        (click)="resetZoom()"
                        style="
                            border-radius: 4px;
                            background-color: #2577da;
                            margin-left: 5px;
                        "
                        matTooltip="Reset map zoom"
                    >
                        <mat-icon>zoom_out_map</mat-icon>
                    </button>
                </div>
            </div>

            <mat-accordion class="device-list">
                <mat-expansion-panel
                    *ngFor="
                        let deviceVM of devices_asOfLastUpdate;
                        trackBy: panelTrackBy
                    "
                    [attr.data-device-id]="deviceVM.device.deviceId"
                    [expanded]="!deviceVM.isCollapsed"
                    #devicePanel
                >
                    <mat-expansion-panel-header (click)="handlePanelClick(deviceVM)">
                        <div
                            style="
                                display: flex;
                                align-items: center;
                                padding: 0 5px;
                                width: 100%;
                            "
                        >
                            <mat-icon
                                aria-hidden="false"
                                aria-label="device status"
                                [style]="
                                    deviceVM.device.isOnline &&
                                    deviceVM.device.hasValidLocation
                                        ? 'color:#20B91E;'
                                        : 'color:#5C5C5C;'
                                "
                                [fontIcon]="
                                    deviceVM.device.isBodycam
                                        ? 'person'
                                        : 'local_taxi'
                                "
                            ></mat-icon>
                            <span style="flex: 1 1 auto; padding: 4px">{{
                                getDeviceDisplayName(deviceVM.device)
                            }}</span>
                            <div
                                *ngIf="!deviceVM.device.isOnline"
                                class="smx-secondary-button mini"
                                style="margin-left: auto; margin-right: 4px"
                            >
                                OFFLINE
                            </div>
                            <div
                                *ngIf="!deviceVM.device.hasValidLocation"
                                class="smx-secondary-button mini"
                                style="margin-left: auto; margin-right: 4px"
                            >
                                LOST GPS
                            </div>
                        </div>
                    </mat-expansion-panel-header>
                    <div>
                        <div
                            style="
                                display: flex;
                                align-items: center;
                                padding: 0 5px;
                            "
                        >
                            <span style="flex: 1 1 auto; padding: 4px"
                                >{{
                                    deviceVM.device.isBodycam
                                        ? "Device: "
                                        : "Unit: "
                                }}
                            </span>
                            <span
                                style="
                                    color: #4d4d4d;
                                    padding: 4px 8px;
                                    font-size: 12px;
                                "
                                >{{
                                    deviceVM.device.isBodycam
                                        ? deviceVM.device.serialNumber
                                        : deviceVM.device.deviceName
                                }}</span
                            >
                        </div>
                        <div
                            style="
                                display: flex;
                                align-items: center;
                                padding: 0 5px;
                            "
                        >
                            <span style="flex: 1 1 auto; padding: 4px"
                                >Device Type:
                            </span>
                            <span
                                style="
                                    color: #4d4d4d;
                                    padding: 4px 8px;
                                    font-size: 12px;
                                "
                                >{{ deviceVM.device.deviceTypeName }}</span
                            >
                        </div>
                        <div
                            *ngIf="deviceVM.device.isBodycam === false"
                            style="
                                display: flex;
                                align-items: center;
                                padding: 0 5px;
                            "
                        >
                            <span style="flex: 1 1 auto; padding: 4px"
                                >Cameras Online:
                            </span>
                            <span
                                style="
                                    color: #4d4d4d;
                                    padding: 4px 8px;
                                    font-size: 12px;
                                "
                            >
                                <ng-container *ngIf="!deviceVM.cameras">
                                    Loading...
                                </ng-container>
                                <ng-container
                                    *ngFor="let camera of deviceVM.cameras"
                                >
                                    <ng-container *ngIf="camera.online">
                                        {{ camera.cameraTitle }}
                                    </ng-container>
                                </ng-container>
                            </span>
                        </div>
                        <div
                            *ngIf="!deviceVM.device.isOnline"
                            style="
                                display: flex;
                                align-items: center;
                                padding: 0 5px;
                            "
                        >
                            <span style="flex: 1 1 auto; padding: 4px"
                                >Last Connected:
                            </span>
                            <span
                                style="
                                    color: #4d4d4d;
                                    padding: 4px 8px;
                                    font-size: 12px;
                                "
                                >{{
                                    timeAgo(deviceVM.device.lastConnected)
                                }}</span
                            >
                        </div>
                        <div style="text-align: right; margin-top: 8px">
                            <button
                                *ngIf="
                                    deviceVM.device.hasLiveViewPermission &&
                                    deviceVM.device.isOnline &&
                                    deviceVM.device.isBodycam
                                "
                                mat-raised-button
                                color="primary"
                                class="sidebar-button"
                                style="
                                    border-radius: 4px;
                                    background-color: #2577da;
                                "
                                (click)="
                                    launchLiveView(deviceVM.device.deviceId)
                                "
                            >
                                <mat-icon>videocam</mat-icon>
                                View Live
                            </button>
                            <button
                                *ngIf="
                                    deviceVM.device.hasLiveViewPermission &&
                                    deviceVM.device.isOnline &&
                                    deviceVM.device.isBodycam === false
                                "
                                [disabled]="
                                    !deviceVM.cameras ||
                                    deviceVM.cameras.length === 0
                                "
                                mat-raised-button
                                color="primary"
                                class="sidebar-button"
                                (click)="
                                    popupCameraSelectForLiveView(
                                        $event,
                                        deviceVM
                                    )
                                "
                            >
                                <mat-icon>videocam</mat-icon>
                                View Live
                            </button>
                            <button
                                mat-raised-button
                                color="primary"
                                class="sidebar-button"
                                style="
                                    border-radius: 4px;
                                    background-color: #2577da;
                                "
                                (click)="handleRequestVideo($event, deviceVM)"
                            >
                                <mat-icon>video_file</mat-icon>
                                Request Video
                            </button>
                            <button
                                *ngIf="deviceVM.device.hasLiveMapPermission"
                                mat-raised-button
                                color="primary"
                                class="sidebar-button"
                                style="
                                    border-radius: 4px;
                                    background-color: #2577da;
                                "
                                (click)="zoomToDevice($event, deviceVM.device)"
                            >
                                <mat-icon>my_location</mat-icon>
                                Locate on Map
                            </button>
                        </div>
                    </div>
                </mat-expansion-panel>
            </mat-accordion>
        </div>
    </div>
    <div id="main-map" style="display: none"></div>
</div>
