<div class="outer-container">
    <div class="inner-container">
        <h1 i18n>
            {{deviceDetails.unitId}} <small>{{deviceDetails.serial}}</small>
        </h1>

        <div class="container-fluid">

            <div class="row">
                <mat-card class="details-card ml-4 mr-5 px-0 py-0">
                    <mat-card-header>
                        <mat-card-title i18n>Details</mat-card-title>
                    </mat-card-header>
                    <mat-card-content class="p-3">

                        <div class="row details">
                            <div class="col-sm-6">
                                <div class="card">
                                    <div class="card-body">
                                        <h5 class="card-title" i18n>Last Connected</h5>
                                        <p class="card-text"
                                           i18n>{{deviceDetails.lastConnected | date:'MMM dd YYYY, h:mm:ss a'}}</p>
                                    </div>
                                </div>

                                <div class="card mt-2">
                                    <div class="card-body">
                                        <h5 class="card-title" i18n>Alarm Transfer</h5>
                                        <p class="card-text">
                                            <span *ngIf="deviceDetails.alarmTransfer" i18n>Enabled</span>
                                            <span *ngIf="!deviceDetails.alarmTransfer" i18n>Disabled</span>
                                        </p>
                                    </div>
                                </div>
                            </div>

                            <div class="col-sm-6" ng-if="vm.transfersInfo.length > 0">
                                <div class="card">
                                    <div class="card-body">
                                        <h5 class="card-title" i18n>Stats</h5>
                                        <p class="card-text" i18n>
                                            <strong>Files Uploaded:</strong> {{deviceDetails.totalFilesUploaded}}
                                        </p>
                                        <p class="card-text" i18n>
                                            <strong>Total
                                                Uploaded:</strong> {{prettyPrintBytes(deviceDetails.totalBytesTransferred)}}
                                        </p>
                                        <p class="card-text" i18n>
                                            <strong>Average Transfer Speed:</strong> {{transferSpeed()}}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </mat-card-content>

                    <mat-card-footer>
                        <mat-card-subtitle></mat-card-subtitle>
                    </mat-card-footer>
                </mat-card>
            </div>

            <div class="row mt-3">
                <mat-card class="details-card ml-4 mr-5 px-0 py-0">
                    <mat-card-header>
                        <mat-card-title i18n>Requests</mat-card-title>
                        <button mat-button class="smx-primary-button ml-auto mb-2 mr-2" (click)="createTransfer()">
                            <div>
                                <fa-icon [icon]="['fas','plus']" class="mr-1"></fa-icon>
                                <span i18n>Create Transfer Request</span>
                            </div>
                        </button>
                    </mat-card-header>
                    <mat-card-content class="pr-3 mx-3 mt-2">
                        <table mat-table [dataSource]="requestsInfoList">

                            <!-- Chevron Column -->
                            <ng-container matColumnDef="chevron">
                                <th mat-header-cell *matHeaderCellDef i18n></th>
                                <td mat-cell *matCellDef="let element">
                                    <fa-icon icon="chevron-down" size="lg"
                                             *ngIf="element.documentsList.length > 0 && element.isOpen"></fa-icon>
                                    <fa-icon icon="chevron-right" size="lg"
                                             *ngIf="element.documentsList.length > 0 && !element.isOpen"></fa-icon>
                                </td>
                            </ng-container>

                            <!-- Created Column -->
                            <ng-container matColumnDef="created">
                                <th mat-header-cell *matHeaderCellDef i18n> Created</th>
                                <td mat-cell *matCellDef="let element">
                                    <div>
                                        <span i18n>{{element.created | date:'MM/dd/YYYY'}}</span><br/>
                                        <span><small i18n>{{element.created | date:'h:mm:ss a'}}</small></span><br/>
                                    </div>
                                </td>
                            </ng-container>

                            <!-- Cameras Column -->
                            <ng-container matColumnDef="cameras">
                                <th mat-header-cell *matHeaderCellDef i18n> Cameras</th>
                                <td mat-cell *matCellDef="let element" i18n>
                                    {{element.cameras}}
                                </td>
                            </ng-container>

                            <!-- Request Start Column -->
                            <ng-container matColumnDef="requestedStart">
                                <th mat-header-cell *matHeaderCellDef i18n> Requested Start</th>
                                <td mat-cell *matCellDef="let element" class="pt-3">
                                    <span i18n>{{element.desiredStart | date:'MM/dd/YYYY'}}</span>
                                    <br/>
                                    <span>
                                        <small i18n>{{element.desiredStart | date:'h:mm:ss a'}}</small>
                                    </span>
                                    <br/>
                                </td>
                            </ng-container>

                            <!-- Request End Column -->
                            <ng-container matColumnDef="requestedEnd">
                                <th mat-header-cell *matHeaderCellDef i18n> Requested End</th>
                                <td mat-cell *matCellDef="let element" class="pt-3">
                                    <span i18n>{{element.desiredStop | date:'MM/dd/YYYY'}}</span><br/>
                                    <span>
                                        <small i18n>{{element.desiredStop | date:'h:mm:ss a'}}</small>
                                    </span>
                                    <br/>
                                </td>
                            </ng-container>


                            <!-- Completed Column -->
                            <ng-container matColumnDef="completed">
                                <th mat-header-cell *matHeaderCellDef i18n>Completed</th>
                                <td mat-cell *matCellDef="let element">
                                    <span i18n>{{element.completed | date:'MM/dd/YYYY'}}</span>
                                    <br/>
                                    <span>
                                        <small i18n>{{element.completed | date:'h:mm:ss a'}}</small>
                                    </span>
                                    <br/>
                                </td>
                            </ng-container>

                            <!-- File Transfer Column -->
                            <ng-container matColumnDef="filesTransfer">
                                <th mat-header-cell *matHeaderCellDef i18n>Files Transfered</th>
                                <td mat-cell *matCellDef="let element" i18n>
                                    {{element.documentsList.length}}
                                </td>
                            </ng-container>

                            <!-- Cancel Transfer Column -->
                            <ng-container matColumnDef="cancel">
                                <th mat-header-cell *matHeaderCellDef i18n></th>
                                <td mat-cell *matCellDef="let element">
                                    <button mat-button class="smx-critical-button" *ngIf="element.status === 0"
                                            (click)="cancelRequest(element.id)" i18n>Cancel
                                    </button>
                                </td>
                            </ng-container>

                            <tr mat-header-row *matHeaderRowDef="displayedColumnsRequests"></tr>
                            <tr mat-row *matRowDef="let row; columns: displayedColumnsRequests;"
                                [ngClass]="{'element-row' : row.documentsList > '0'}"
                                [cdkDetailRow]="row"
                                [cdkDetailRowTpl]="tpl">
                            </tr>
                        </table>

                        <mat-paginator #paginator
                                       [length]="resultsLengthRequests"
                                       [pageIndex]="pageEvent.pageIndex"
                                       [pageSize]="pageEvent.pageSize"
                                       [pageSizeOptions]="pageSizeOptions"
                                       (page)="onRequestsPageFired($event)">
                        </mat-paginator>

                        <!--Expandable row that is hidden by default.-->
                        <ng-template #tpl let-element>
                            <td class="mat-row detail-row" [@detailExpand] style="overflow: hidden"
                                [attr.colspan]="displayedColumnsRequests.length">
                                <div *ngFor="let document of element.documentsList">
                                    <fa-icon icon="level-up-alt" rotate="90" size="lg"
                                             [classes]="['ml-5', 'mr-3']"></fa-icon>
                                    <span *ngIf="!document.permissions.canView">{{document.filename}}</span>
                                    <a *ngIf="document.permissions.canView"
                                       (click)="openDocInNewTab($event, document.id)"
                                       href="javascript.void(0);"
                                       i18n>{{document.filename}}</a>
                                    -
                                    <span><small i18n>{{document.created | date: 'h:mm:ss a'}}</small></span>
                                </div>
                            </td>
                        </ng-template>
                    </mat-card-content>
                </mat-card>
            </div>

            <div class="row mt-3">
                <mat-card class="details-card ml-4 mr-5 px-0 py-0">
                    <mat-card-header>
                        <mat-card-title i18n>Alarm Transfers</mat-card-title>
                    </mat-card-header>
                    <mat-card-content class="pr-3 mx-3 mt-2 pb-2">
                        <table mat-table [dataSource]="transfersInfoList">

                            <!-- Uploaded Column -->
                            <ng-container matColumnDef="uploaded">
                                <th mat-header-cell *matHeaderCellDef i18n> Uploaded</th>
                                <td mat-cell *matCellDef="let element">
                                    <span i18n>{{element.completed | date:'MM/dd/YYYY'}}</span><br/>
                                    <span><small i18n>{{element.completed | date:'h:mm:ss a'}}</small></span><br/>
                                    <mat-chip-set>
                                        <mat-chip *ngIf="element.isAlarm" [disabled]="true" class="mat-chip-static"
                                                  i18n>Important
                                        </mat-chip>
                                    </mat-chip-set>
                                </td>
                            </ng-container>

                            <!-- File Name Column -->
                            <ng-container matColumnDef="file">
                                <th mat-header-cell *matHeaderCellDef i18n> File</th>
                                <td mat-cell *matCellDef="let element">
                                    <span *ngIf="!element.permissions.canView" i18n>{{element.filename}}</span>
                                    <a *ngIf="element.permissions.canView"
                                       (click)="openDocInNewTab($event, element.documentId)"
                                       href="javascript.void(0);"
                                       i18n>{{element.filename}}</a>
                                </td>
                            </ng-container>

                            <!-- Created Date Column -->
                            <ng-container matColumnDef="created">
                                <th mat-header-cell *matHeaderCellDef i18n> Created</th>
                                <td mat-cell *matCellDef="let element" class="pt-3">
                                    <span i18n>{{element.created | date: 'MM/dd/YYYY'}}</span><br/>
                                    <span><small i18n>{{element.created | date: 'h:mm:ss a'}}</small></span>
                                </td>
                            </ng-container>

                            <!-- File Size Column -->
                            <ng-container matColumnDef="fileSize">
                                <th mat-header-cell *matHeaderCellDef i18n> File Size</th>
                                <td mat-cell *matCellDef="let element" class="pt-3" i18n>
                                    {{prettyPrintBytes(element.contentLength)}}
                                </td>
                            </ng-container>


                            <!-- Transfer Speed Column -->
                            <ng-container matColumnDef="transferSpeed">
                                <th mat-header-cell *matHeaderCellDef i18n>Transfer Speed</th>
                                <td mat-cell *matCellDef="let element">
                                    <div *ngIf="element.transferTimeSeconds == 0" i18n>
                                        Not Available
                                    </div>
                                    <div *ngIf="element.transferTimeSeconds != 0" i18n>
                                        {{prettyPrintBytes(element.contentLength / element.transferTimeSeconds)}}/s
                                    </div>
                                </td>
                            </ng-container>

                            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                        </table>

                        <mat-paginator #paginator
                                       [length]="resultsLengthTransfers"
                                       [pageIndex]="pageEvent.pageIndex"
                                       [pageSize]="pageEvent.pageSize"
                                       [pageSizeOptions]="pageSizeOptions"
                                       (page)="onTransferPageFired($event)">
                        </mat-paginator>
                    </mat-card-content>

                    <mat-card-footer>
                        <mat-card-subtitle></mat-card-subtitle>
                    </mat-card-footer>
                </mat-card>
            </div>
        </div>
    </div>
</div>
