import {Component, ViewChild} from '@angular/core';
import {FormBuilder, FormControl, FormGroup} from "@angular/forms";
import {TitleService} from "../../../services/title.service";
import {Router} from "@angular/router";
import {CategoryViewModel, GroupViewModel, SecuraMaxApiService} from "../../../services/api/securamaxapi.service";
import {DocumentStatusViewModel} from "../../navbar/navbar.component";
import {documentStatus} from "../../../shared/helpers/document-status-const";
import {DocumentSearchService} from "../../../services/document-search.service";
import {SearchFilter} from "../../../models/search-filter.model";
import {UserSelectClearService} from "../../../services/user/user-select-clear.service";
import {DateRangePickerComponent} from "../../../shared/components/daterangepicker/daterangepicker.component";

export interface FilterOptionsForm {
    groups: FormControl<string[]>;
    statuses: FormControl<string[]>;
    categories: FormControl<string[]>;
    sharedWithAnyoneOnly: FormControl<boolean>;
    sharedWithMeOnly: FormControl<boolean>;
    sharedByMeOnly: FormControl<boolean>;
    requireInitialTagging: FormControl<boolean>;
    includeDeleted: FormControl<boolean>;
    importantOnly: FormControl<boolean>;
    lockedFromDeletion: FormControl<boolean>;
    unlockedFromDeletion: FormControl<boolean>;
    excludeRedactions: FormControl<boolean>;
    onlyRedactions: FormControl<boolean>;
}


@Component({
    selector: 'app-documents-search2',
    templateUrl: './documents-search2.component.html',
    styleUrls: ['./documents-search2.component.scss']
})
export class DocumentsSearch2Component {
    filterOptionsForm: FormGroup<FilterOptionsForm>;
    filterOptionsForm_start: string;
    filterOptionsForm_end: string;
    filterOptionsForm_userId: number;
    filterOptionsForm_userName: string;
    filterOptionsForm_groupsList: GroupViewModel[] = [];
    filterOptionsForm_statusList: DocumentStatusViewModel[] = [];
    filterOptionsForm_categoryList: CategoryViewModel[] = []
    filterOptionsForm_hasCompletedInitialization = false;
    userSelectName: string = "filterOptionsForm_userSelect";

    @ViewChild('dateRangePicker') dateRangePicker: DateRangePickerComponent;

    constructor(private fb: FormBuilder,
                public documentSearchService: DocumentSearchService,
                private userSelectClearService: UserSelectClearService,
                private titleService: TitleService,
                private apiService: SecuraMaxApiService,
                public router: Router,
    ) {
        this.filterOptionsForm = fb.group({
            groups: [[] as string[]],
            statuses: [[] as string[]],
            categories: [[] as string[]],
            sharedWithAnyoneOnly: [false],
            sharedWithMeOnly: [false],
            sharedByMeOnly: [false],
            requireInitialTagging: [false],
            includeDeleted: [false],
            importantOnly: [false],
            lockedFromDeletion: [false],
            unlockedFromDeletion: [false],
            excludeRedactions: [false],
            onlyRedactions: [false]
        })
    }

    ngOnInit() {
        this.titleService.setTitle("Search");
        this.apiService.group_GetAll().subscribe(data => {
            this.filterOptionsForm_groupsList = data;
        });
        this.apiService.categories_GetAll().subscribe(data => {
            this.filterOptionsForm_categoryList = data;
        });

        var statuses = this.getSearchableDocumentStatuses();
        statuses.forEach(status => {
            this.filterOptionsForm_statusList.push(status);
        });

        this.filterOptionsForm.valueChanges.subscribe(x => {
            if (!this.filterOptionsForm_hasCompletedInitialization) {
                return;
            }

            const currentSearch = this.documentSearchService.currentSearchFilter.value;
            currentSearch.groups = x.groups;
            currentSearch.status = x.statuses;
            currentSearch.categories = x.categories;
            currentSearch.sharedWithAnyoneOnly = x.sharedWithAnyoneOnly;
            currentSearch.sharedWithMeOnly = x.sharedWithMeOnly;
            currentSearch.sharedByMeOnly = x.sharedByMeOnly;
            currentSearch.requireInitialTagging = x.requireInitialTagging;
            currentSearch.includeDeleted = x.includeDeleted;
            currentSearch.importantOnly = x.importantOnly;
            currentSearch.lockedFromDeletion = x.lockedFromDeletion;
            currentSearch.unlockedFromDeletion = x.unlockedFromDeletion;
            currentSearch.excludeRedactions = x.excludeRedactions;
            currentSearch.onlyRedactions = x.onlyRedactions;
            this.documentSearchService.updateSearch(currentSearch);
        })



        this.filterOptionsForm_initializeBasedOnCurrentSearch();
    }

    ngDoCheck() {
        if (this.router.url === '/search')
        {
            this.router.navigate(['/search/documents']).then(value => {});
        }
    }

    filterOptionsForm_initializeBasedOnCurrentSearch() {
        const currentSearch = this.documentSearchService.currentSearchFilter.value;
        this.filterOptionsForm_start = currentSearch.start;
        this.filterOptionsForm_end = currentSearch.end;
        if (currentSearch.userIds && currentSearch.userIds.length > 0) {
            this.filterOptionsForm_userId = Number(currentSearch.userIds);
        }
        this.filterOptionsForm.controls.groups.setValue(currentSearch.groups);
        this.filterOptionsForm.controls.statuses.setValue(currentSearch.status);
        this.filterOptionsForm.controls.categories.setValue(currentSearch.categories);
        this.filterOptionsForm.controls.sharedWithAnyoneOnly.setValue(currentSearch.sharedWithAnyoneOnly);
        this.filterOptionsForm.controls.sharedWithMeOnly.setValue(currentSearch.sharedWithMeOnly);
        this.filterOptionsForm.controls.sharedByMeOnly.setValue(currentSearch.sharedByMeOnly);
        this.filterOptionsForm.controls.requireInitialTagging.setValue(currentSearch.requireInitialTagging);
        this.filterOptionsForm.controls.includeDeleted.setValue(currentSearch.includeDeleted);
        this.filterOptionsForm.controls.importantOnly.setValue(currentSearch.importantOnly);
        this.filterOptionsForm.controls.lockedFromDeletion.setValue(currentSearch.lockedFromDeletion);
        this.filterOptionsForm.controls.unlockedFromDeletion.setValue(currentSearch.unlockedFromDeletion);
        this.filterOptionsForm.controls.excludeRedactions.setValue(currentSearch.excludeRedactions);
        this.filterOptionsForm.controls.onlyRedactions.setValue(currentSearch.onlyRedactions);
        this.filterOptionsForm_hasCompletedInitialization = true;
    }

    userSelect_change() {
        const currentSearch = this.documentSearchService.currentSearchFilter.value;
        if (this.filterOptionsForm_userId) {
            currentSearch.userIds = [this.filterOptionsForm_userId.toString()];
            this.documentSearchService.updateSearch(currentSearch);
        } else {
            currentSearch.userIds = null;
            this.documentSearchService.updateSearch(currentSearch);
        }
    }


    getSearchableDocumentStatuses() : DocumentStatusViewModel[]
    {
        var statusArray = [];

        var active = new DocumentStatusViewModel();
        active.name = "Active";
        active.statusId = documentStatus.active;
        statusArray.push(active);

        var archived = new DocumentStatusViewModel();
        archived.name = "Archived";
        archived.statusId = documentStatus.archived;
        statusArray.push(archived);

        var pendingRetrieval = new DocumentStatusViewModel();
        pendingRetrieval.name = "Pending Archive Retrieval";
        pendingRetrieval.statusId = documentStatus.pendingArchiveRetrieval;
        statusArray.push(pendingRetrieval);

        var deleted = new DocumentStatusViewModel();
        deleted.name = "Deleted";
        deleted.statusId = documentStatus.deleted;
        statusArray.push(deleted);

        return statusArray;
    }

    clear_click() {
        this.filterOptionsForm.reset();
        this.filterOptionsForm_userId = null;
        this.dateRangePicker.reset();
        this.userSelectClearService.announceUserClear(this.userSelectName);
        this.documentSearchService.updateSearch({});
    }

    daterangepicker_change(ignored: any) {
        const currentSearch = this.documentSearchService.currentSearchFilter.value;
        currentSearch.start = this.filterOptionsForm_start;
        currentSearch.end = this.filterOptionsForm_end;
        this.documentSearchService.updateSearch(currentSearch);
    }
}
