import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { RedactionItem, DocumentDetailsViewModel, SecuraMaxApiService } from 'src/app/services/api/securamaxapi.service';
import { VgApiService } from '@videogular/ngx-videogular/core';
import { TitleService } from 'src/app/services/title.service';
import { SnackbarService } from 'src/app/services/snackbar.service';


@Component({
    selector: 'app-redaction-createV2',
    templateUrl: './redaction-createV2.component.html',
    styleUrls: ['./redaction-createV2.component.css']
})
export class RedactionCreateV2Component implements OnInit {
    redactionId: string;
    saving: boolean = false;
    loading: boolean = true;
    vgAPI: VgApiService;
    redaction: RedactionItem = new RedactionItem();
    document: DocumentDetailsViewModel;
    documentId: string;
    videoSrcUrl: string = null;

    videoTrimStart: number = 0;
    formattedVideoTrimStart: string = this.numToTimespanString(this.videoTrimStart)

    videoTrimEnd: number = 10;
    formattedVideoTrimEnd: string = this.numToTimespanString(this.videoTrimEnd)

    videoTrimMaxEnd: number = 10;

    audioTrimStart: number = 0;
    formattedAudioTrimStart: string = this.numToTimespanString(this.audioTrimStart)

    audioTrimEnd: number = 10;
    formattedAudioTrimEnd: string = this.numToTimespanString(this.audioTrimEnd)


    audioTrimMaxEnd: number = 10;
    blurAmount: number = 0;
    currentTime: string;
    timeLeft: string;
    duration: number;

    constructor(private securamaxApiService: SecuraMaxApiService, private router: Router, private route: ActivatedRoute, private titleService: TitleService, private toastr: SnackbarService) {

    }

    ngOnInit(): void {
        this.titleService.setTitle('Create Redaction');
        this.documentId = this.route.snapshot.paramMap.get('id');
        this.redactionId = this.route.snapshot.paramMap.get('redactionId');
        this.securamaxApiService.documents_Get(this.documentId).subscribe(doc => {
            this.document = doc;

            if (this.document.versions) {
                this.videoSrcUrl = this.document.versions.find(x =>
                    x.videoFormat === "mp4"
                )?.url;
            }
            this.loading = false;

        }, (err) => {
            this.toastr.error('Error occurred while getting the document.');
        });
    }

    numToTimespanString(seconds: number, charsToTrim?: number) {
        const diff = this.videoTrimMaxEnd - seconds;
        if (diff < 0.5) {
            seconds = this.videoTrimMaxEnd;
        }

        var date = new Date(null);
        date.setMilliseconds(seconds * 1000);
        if (charsToTrim) {
            var isoDate = date.toISOString().substr(11, 12 - charsToTrim);
        } else {
            var isoDate = date.toISOString().substr(11, 12);
        }
        return isoDate.replace('-', ':');
    }

    stringToTimespanNum(timespanString: string) {
        var date = new Date('1970-01-01T' + timespanString + 'Z');
        //convert to seconds
        return date.getMinutes() * 60 + date.getSeconds() + date.getMilliseconds() * .001;
    }

    submit(): void {
        this.saving = true;
        this.redaction.documentId = this.documentId;
        this.redaction.videoStart = this.numToTimespanString(this.videoTrimStart);
        this.redaction.videoEnd = this.numToTimespanString(this.videoTrimEnd);
        this.redaction.audioStart = this.numToTimespanString(this.audioTrimStart);
        this.redaction.audioEnd = this.numToTimespanString(this.audioTrimEnd);
        this.redaction.blurAmount = this.blurAmount;

        this.securamaxApiService.redaction_Post(this.redaction).toPromise().then((id) => {
            this.redactionId = id + '';
            this.saving = false;
            this.toastr.success('Created successfully!');
            this.router.navigate(['documents', this.document.id, 'redactions', id]);
        }, (failure) => {
            this.saving = false;
            this.toastr.error('Create failed: ' + failure);
        });
    }

    onPlayerReady(api: VgApiService) {
        this.vgAPI = api;

        this.vgAPI.getDefaultMedia().subscriptions.timeUpdate.subscribe(
            () => {
                var currenttime = Math.round((this.vgAPI.getDefaultMedia().currentTime + Number.EPSILON) * 100) / 100;
                var timeleft = Math.round((this.duration - currenttime + Number.EPSILON) * 100) / 100;

                var currenttimeminutes = Math.floor(currenttime / 60);
                var currenttimeseconds = Math.round((currenttime % 60 + Number.EPSILON) * 100) / 100;

                var timeleftminutes = Math.floor(timeleft / 60);
                var timeleftseconds = Math.round((timeleft % 60 + Number.EPSILON) * 100) / 100;

                var currenttimesecondscorrected = currenttimeseconds.toString();
                if (currenttimeseconds < 10) {
                    currenttimesecondscorrected = "0" + currenttimeseconds;
                }

                var timeleftsecondscorrected = timeleftseconds.toString();
                if (timeleftseconds < 10) {
                    timeleftsecondscorrected = "0" + timeleftseconds;
                }

                this.currentTime = currenttimeminutes + ":" + currenttimesecondscorrected;
                this.timeLeft = timeleftminutes + ":" + timeleftsecondscorrected;

                console.log("currenttime " + currenttime);
                console.log("timeleft " + timeleft);
                console.log("currenttimeminutes " + currenttimeminutes);
                console.log("currenttimeseconds " + currenttimeseconds);
                console.log("timeleftminutes " + timeleftminutes);
                console.log("timeleftseconds " + timeleftseconds);
            }
        );

        this.vgAPI.getDefaultMedia().subscriptions.loadedMetadata.subscribe(
            () => {
                this.duration = this.vgAPI.getDefaultMedia().duration;
                this.audioTrimMaxEnd = this.duration;
                this.videoTrimMaxEnd = this.duration;
                this.audioTrimEnd = this.duration;
                this.videoTrimEnd = this.duration;
                this.updateVideoTrim();
            }
        );
    }

    trimAudioToVideo() {
        if (this.audioTrimStart < this.videoTrimStart) {
            this.audioTrimStart = this.videoTrimStart;
        }

        if (this.audioTrimEnd > this.videoTrimEnd) {
            this.audioTrimEnd = this.videoTrimEnd;
        }

        if (this.audioTrimStart > this.videoTrimEnd) {
            this.audioTrimStart = this.videoTrimStart;
            this.audioTrimEnd = this.videoTrimEnd;
        }

        if (this.audioTrimEnd < this.videoTrimStart) {
            this.audioTrimStart = this.videoTrimStart;
            this.audioTrimEnd = this.videoTrimEnd;
        }

        this.updateAudioTrim();
    }

    handleVideoTrimStart(timeString: string): void {
        timeString = this.timeStringNormalize(timeString);
        if (this.isValidDate(timeString)) this.videoTrimStart = this.stringToTimespanNum(timeString);
        this.updateVideoTrim();
    }

    handleVideoTrimEnd(timeString: string): void {
        timeString = this.timeStringNormalize(timeString);
        if (this.isValidDate(timeString)) this.videoTrimEnd = this.stringToTimespanNum(timeString);
        this.updateVideoTrim();
    }

    updateVideoTrim(): void {
        if (this.videoTrimMaxEnd - this.videoTrimEnd < 0.5) this.videoTrimEnd = this.videoTrimMaxEnd;
        if (this.videoTrimStart < 0.5) this.videoTrimStart = 0;

        this.formattedVideoTrimEnd = this.numToTimespanString(this.videoTrimEnd, 2);
        this.formattedVideoTrimStart = this.numToTimespanString(this.videoTrimStart, 2);
        this.trimAudioToVideo();
    }

    handleAudioTrimEnd(timeString: string): void {
        timeString = this.timeStringNormalize(timeString);
        if (this.isValidDate(timeString)) this.audioTrimEnd = this.stringToTimespanNum(timeString);
        this.updateAudioTrim();
    }

    handleAudioTrimStart(timeString: string): void {
        timeString = this.timeStringNormalize(timeString);
        if (this.isValidDate(timeString)) this.audioTrimStart = this.stringToTimespanNum(timeString);
        this.updateAudioTrim();
    }

    updateAudioTrim(): void {
        if (this.videoTrimMaxEnd - this.audioTrimEnd < 0.5) this.audioTrimEnd = this.videoTrimMaxEnd;
        if (this.audioTrimStart < 0.5) this.audioTrimStart = 0;

        this.formattedAudioTrimEnd = this.numToTimespanString(this.audioTrimEnd, 2);
        this.formattedAudioTrimStart = this.numToTimespanString(this.audioTrimStart, 2)
    }

    filterTimeInput(event: KeyboardEvent) {
        const input = event.target as HTMLInputElement;
        const value = input.value;
        const allowedChars = /^[0-9:.]+$/;

        if (!allowedChars.test(value)) {
            input.value = value.replace(/[^0-9:.]/g, '');
        }
    }

    timeStringNormalize(timeString: string) {
        // disallows any '.' or ':' chars that are out of place
        let str = timeString.replace(/[:.]/g, '').padEnd(8, '0');

        return `${str.slice(0, 2)}:${str.slice(2, 4)}:${str.slice(4, 6)}.${str.slice(6)}`;

    }

    filterBlurInput(event: KeyboardEvent) {
        const input = event.target as HTMLInputElement;
        const value = input.value;
        const allowedChars = /[0-9]+/;

        if (!allowedChars.test(value)) {
            input.value = value.replace(/[^0-9]/g, '');

            this.blurAmount = isNaN(parseInt(input.value)) ? 0 : parseInt(input.value)
        }
    }

    isValidDate(timespanString) {
        const regex = /^(\d{2}):(\d{2}):(\d{2})\.(\d*)$/;
        const match = timespanString.match(regex);

        if (!match) {
            console.log("Invalid format");
            return false;
        }

        const [, hours, minutes, seconds] = match;

        if (
            parseInt(hours) > 23 ||
            parseInt(minutes) > 59 ||
            parseInt(seconds) > 59
        ) {
            console.log("Invalid format");
            return false;
        }
        return true;
    }
}

