import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { Observable, Subscription } from 'rxjs';
import { UserSelectModel } from 'src/app/models/user-select.model';

export enum shareUserType{
  exisitingUser = 0,
  newUser = 1
}

export interface ShareDialogData {
  shareSelect: UserSelectModel;
  newShareUserInput: string;
  shareUserType: shareUserType;
  expireDateTime: Date;
  canView: boolean;
  canEditMetadata: boolean;
  canDownload: boolean;
  canReShare: boolean;
  shareMinDate: Date;
  shareSubject: string;
}

@Component({
  selector: 'app-cases-details-share-dialog',
  templateUrl: './cases-details-share-dialog.component.html',
  styleUrls: ['./cases-details-share-dialog.component.css']
})
export class CasesDetailsShareDialogComponent implements OnInit {
  minShareDate: Date;
  @ViewChild('shareDialogForm', { static: true }) shareDialogForm: NgForm;
  shareDialogFormValueChangesSubscription: Subscription;

  constructor(public dialogRef: MatDialogRef<CasesDetailsShareDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ShareDialogData) { }

  CloseShareSelected() {
    this.dialogRef.close();
  }

  ngOnInit(): void {
    this.minShareDate = new Date();
    this.data.canView = true;
    this.data.shareUserType = 0;
  }



  ngAfterViewInit() {
    this.shareDialogFormValueChangesSubscription = this.shareDialogForm.valueChanges.subscribe(x => {
      const f = this.shareDialogForm.form;
      const c = f.controls;
      if (c['shareUserType'].value === 0) {
        if (!this.data.shareSelect.associatedUserId) {
          if (f.hasError("no existing user selected")) {
          } else {
            f.setErrors({ "no existing user selected" : true });
          }
        } else {
          if (f.hasError("no existing user selected")) {
            f.setErrors({ "no existing user selected" : null });
          } else {
          }
        }
      }
    })
  }


  ngOnDestroy() {
    this.shareDialogFormValueChangesSubscription.unsubscribe();
  }



  onSubmit(): void {
    console.log("here");
  }
}