import {
    AfterViewInit,
    Component,
    OnDestroy,
    OnInit,
    QueryList,
    ViewChildren,
    HostListener,
} from '@angular/core';
import { TitleService } from 'src/app/services/title.service';
import { SecuraMaxApiService } from 'src/app/services/api/securamaxapi.service';
import { Loader } from '@googlemaps/js-api-loader';
import { MarkerClusterer } from '@googlemaps/markerclusterer';
import { MatDialog } from '@angular/material/dialog';
import { FilterDialog } from '../../shared/components/filter-dialog/filter-dialog.component';
import { MatExpansionPanel } from '@angular/material/expansion';
import * as signalR from '@microsoft/signalr';
import { HubConnection } from '@microsoft/signalr';
import { SnackbarService } from '../../services/snackbar.service';
import { CreateRequestDialogComponent } from '../../shared/components/create-request-dialog/create-request-dialog.component';
import { from } from 'rxjs';
import { LiveInactivityDialogComponent } from './live-inactivity-dialog/live-inactivity-dialog.component';
import GUI from 'lil-gui';
import { delay } from '../../util/common-functions';
import { LiveDownloadDialogComponent } from './live-download-dialog/live-download-dialog.component';
import { LiveCameraSelectDialogComponent } from './live-camera-select-dialog/live-camera-select-dialog.component';

export interface IDeviceData {
    deviceId: string;
    deviceName: string;
    serialNumber: string;
    assignedUsername: string;
    firstName: string | null;
    lastName: string | null;
    latitude: number;
    longitude: number;
    address: string;
    city: string;
    state: string;
    isOnline: boolean;
    isBodycam: boolean;
    hasValidLocation: boolean;
    lastConnected: string;
    deviceTypeName: string;
    deviceModelNumber: string;
    hasLiveMapPermission: boolean;
    hasLiveViewPermission: boolean;
    cameras: [IDeviceCameraViewModel];
}

export interface IDeviceCameraViewModel {
    cameraTitle: string;
    online: boolean;
    recording: boolean;
    index?: number;
}

export interface IDeviceViewModel {
    device: IDeviceData;
    markerElement: any | null;
    marker: any | null;
    isCollapsed: boolean;
    location: string | null;
    basicContent: any | null;
    detailsContent: any | null;
    cameras: IDeviceCameraViewModel[] | null;
}

export interface ILiveComponentSearchFilter {
    searchTerm: string;
}

@Component({
    selector: 'app-live',
    templateUrl: './live.component.html',
    styleUrls: ['./live.component.css'],
})
export class LiveComponent implements OnInit, AfterViewInit, OnDestroy {
    debugGui: GUI;
    debugState = {
        enabled: false,
        testPositionLat: 0.0,
        testPositionLong: 0.0,
        simulateGpsLoss: true,
        showOtherDevices: false,
        hideAllDevices: false,
    };

    events = [
        'update complete',
        'error',
        'search or filter change',
        'auto refresh timer',
        'inactivity timer',
        'disconnected',
        'reconnecting',
        'reconnecting after inactive',
    ];
    eventListeners = {};
    states = [
        'unknown',
        'waiting for connection',
        'ready',
        'processing search or filter change',
        'processing update',
        'inactive',
        'disconnected and attempting to reconnect',
        'shutting down',
        'error',
    ];
    state: string = 'uninitialized';
    state_hasCompletedAtLeastOneUpdateDisplay = false;

    // Pro-Vision offices.
    defaultLocationLat = 42.80723492899391;
    defaultLocationLong = -85.6757442080222;

    @ViewChildren('devicePanel') panels: QueryList<MatExpansionPanel>;
    devices_asOfLastUpdate: IDeviceViewModel[] = [];
    filter: ILiveComponentSearchFilter = { searchTerm: '' };
    map: google.maps.Map;
    clusterer: MarkerClusterer;

    conn: HubConnection;
    autoRefreshTimerId: NodeJS.Timeout | null = null;
    inactivityTimerId: NodeJS.Timeout | null = null;

    currentUserName: string | null = null;

    filterSettings: any = {
        sortField: 'deviceStatus',
        sortDirection: 'asc',
        addressDisplay: 'cityState',
        filter: {
            showOffline: true,
            showBodycams: true,
            showVehicles: true,
        },
    };

    constructor(
        public dialog: MatDialog,
        private titleService: TitleService,
        private apiService: SecuraMaxApiService,
        private toastr: SnackbarService
    ) {
        this.state = 'waiting for connection';

        this.eventListeners['error'] = () => {
            // todo logging

            if (this.state == 'error') {
                return;
            }

            if (this.state == 'inactive') {
                return;
            }

            if (this.state == 'disconnected and attempting to reconnect') {
                return;
            }

            // Assume disconnect/reconnect might fix it
            window.dispatchEvent(new CustomEvent('disconnected'));
            //toastr.error("Unexpected error occured.");
        };

        this.eventListeners['inactivity timer'] = () => {
            if (this.state !== 'ready') {
                return;
            }

            this.state = 'inactive';

            from(this.conn.stop()).subscribe(
                () => {},
                () => {}
            );

            this.openInactivityDialog();
        };

        this.eventListeners['update complete'] = (event: any) => {
            if (this.state !== 'ready') {
                return;
            }

            const updatedDevices = event.detail[0].map((x) =>
                this.createDeviceVMForDevice(x)
            );
            this.handleDeviceUpdateOrFilterChange(updatedDevices);
            this.state_hasCompletedAtLeastOneUpdateDisplay = true;
        };

        this.eventListeners['reconnecting'] = () => {
            if (this.state !== 'disconnected and attempting to reconnect') {
                return;
            }

            // Handle the case that another handler in the event queue already reconnected.
            if (this.conn.state === 'Connected') {
                this.state = 'ready';
                this.toastr.success('Reconnected to live service.');
                return;
            }

            this.conn
                .start()
                .then(() => {
                    this.state = 'ready';
                    this.toastr.success('Reconnected to live service.');
                })
                .catch((err) => {
                    this.state = 'disconnected and attempting to reconnect';
                    console.error(err);
                    delay(3000).then(() => {
                        this.toastr.error(
                            'Failed to reconnect, trying again...'
                        );
                        window.dispatchEvent(new CustomEvent('reconnecting'));
                    });
                });
        };

        this.eventListeners['disconnected'] = () => {
            if (this.state === 'inactive') {
                return;
            }

            // Handle the case that another handler in the event queue already reconnected.
            if (this.conn.state === 'Connected') {
                this.state = 'ready';
                this.toastr.success('Reconnected to live service.');
                return;
            }

            this.state = 'disconnected and attempting to reconnect';

            this.toastr.error(
                'Disconnected from live service, trying to reconnect...'
            );

            this.conn
                .start()
                .then(() => {
                    this.state = 'ready';
                    this.toastr.success('Reconnected to live service.');
                })
                .catch((err) => {
                    console.error(err);
                    delay(3000).then(() => {
                        this.toastr.error(
                            'Failed to reconnect, trying again...'
                        );
                        window.dispatchEvent(new CustomEvent('reconnecting'));
                    });
                });
        };

        this.eventListeners['search or filter change'] = () => {
            if (this.state !== 'ready') {
                return;
            }

            this.filter = Object.assign({}, this.filter);
            this.handleDeviceUpdateOrFilterChange(this.devices_asOfLastUpdate);
        };

        this.eventListeners['auto refresh timer'] = () => {
            if (this.state !== 'ready') {
                return;
            }

            if (this.debugState.enabled) {
                from(
                    this.conn.invoke('GetDevicesList', this.debugState)
                ).subscribe(
                    () => {},
                    () => {
                        window.dispatchEvent(new CustomEvent('error'));
                    }
                );
            } else {
                from(this.conn.invoke('GetDevicesList', null)).subscribe(
                    () => {},
                    () => {
                        window.dispatchEvent(new CustomEvent('error'));
                    }
                );
            }
        };

        this.eventListeners['reconnecting after inactive'] = () => {
            if (this.state !== 'inactive') {
                return;
            }

            // Handle the case that another handler in the event queue already reconnected.
            if (this.conn.state === 'Connected') {
                this.state = 'ready';
                this.toastr.success('Reconnected to live service.');
                return;
            }

            this.conn
                .start()
                .then(() => {
                    this.state = 'ready';
                    this.toastr.success('Reconnected to live service.');
                })
                .catch((err) => {
                    console.error(err);
                    delay(3000).then(() => {
                        this.toastr.error(
                            'Failed to reconnect, trying again...'
                        );
                        this.state = 'disconnected and attempting to reconnect';
                        window.dispatchEvent(new CustomEvent('reconnecting'));
                    });
                });
        };

        for (const [event, listener] of Object.entries(this.eventListeners)) {
            (<any>window).addEventListener(event, listener);
        }

        this.autoRefreshTimerId = setInterval(() => {
            window.dispatchEvent(new CustomEvent('auto refresh timer'));
        }, 10 * 1000);

        const inactivityTimerDurationInMillis = 60 * 60 * 1000;
        this.inactivityTimerId = setInterval(() => {
            window.dispatchEvent(new CustomEvent('inactivity timer'));
        }, inactivityTimerDurationInMillis);

        this.conn = new signalR.HubConnectionBuilder()
            .withUrl('/live/hub')
            .build();
        this.conn.serverTimeoutInMilliseconds = 60 * 1000;
        this.conn.keepAliveIntervalInMilliseconds = 30 * 1000;
        this.conn.on('GetDevicesList_Callback', (data) => {
            window.dispatchEvent(
                new CustomEvent('update complete', { detail: data })
            );
        });
        this.conn.on('GetDeviceDetails_Callback', (data) => {
            const device: IDeviceData = data[0];
            const deviceVM = this.devices_asOfLastUpdate.find(
                (x) => x.device.serialNumber === device.serialNumber
            );
            deviceVM.location = device.address;
            deviceVM.cameras = device.cameras;
            for (let i = 0; i < deviceVM.cameras.length; i++) {
                deviceVM.cameras[i].index = i;
            } 
        });
        this.conn.onclose(() => {
            window.dispatchEvent(new CustomEvent('disconnected'));
        });
    }

    ngOnInit() {
        from(this.conn.start()).subscribe();

        (<any>window).handleRetrieveVideo = (deviceId: string) => {
            this.apiService.devices_GetDevice(deviceId).subscribe((device) => {
                const dialogRef = this.dialog.open(
                    CreateRequestDialogComponent,
                    {
                        data: {
                            id: device.id,
                            deviceName: device.name,
                            deviceSerial: device.serialNumber,
                        },
                    }
                );
                dialogRef.afterClosed().subscribe(() => {});
            });
        };

        // this.debugState.enabled = true;
        // this.debugState.showOtherDevices = true;
        // this.debugCreateGui();
    }

    ngAfterViewInit() {
        this.titleService.setTitle('Live');
        from(this.loadMapsApi()).subscribe(() => {
            this.state = 'ready';
        });
    }

    ngOnDestroy() {
        delete (<any>window).handleRetrieveVideo;
        for (const [event, listener] of Object.entries(this.eventListeners)) {
            (<any>window).removeEventListener(event, listener);
        }
        clearInterval(this.inactivityTimerId);
        clearInterval(this.autoRefreshTimerId);
        this.conn.stop();
        if (this.debugGui) {
            this.debugGui.destroy();
        }
    }

    @HostListener('document:keydown', ['$event'])
    handleKeyboardEvent(event: KeyboardEvent) {
        if (event.ctrlKey && event.key === 'F1') {
            event.preventDefault();
            if (this.debugGui) {
                if (this.debugGui._hidden) {
                    this.debugGui.show();
                } else {
                    this.debugGui.hide();
                }
            } else {
                this.debugCreateGui();
            }
        }
    }

    debugCreateGui() {
        this.debugGui = new GUI({ title: 'Debug' });
        this.debugGui.add(this.debugState, 'enabled');
        this.debugGui.add(this.debugState, 'testPositionLat', -55, 55, 0.01);
        this.debugGui.add(this.debugState, 'testPositionLong', -55, 55, 0.01);
        this.debugGui.add(this.debugState, 'simulateGpsLoss');
        this.debugGui.add(this.debugState, 'showOtherDevices');
        this.debugGui.add(this.debugState, 'hideAllDevices');
    }

    async loadMapsApi() {
        const loader = new Loader({
            apiKey: 'AIzaSyBHEXlzkGnd2Q-Hw_Bb9MT0PTZzikt4Ft4',
            version: 'weekly',
        });
        await loader.load();
        const { Map } = (await google.maps.importLibrary(
            'maps'
        )) as google.maps.MapsLibrary;
        await google.maps.importLibrary('marker');
        const { LatLng } = (await google.maps.importLibrary(
            'core'
        )) as google.maps.CoreLibrary;
        const center = new LatLng(37.43238031167444, -122.16795397128632);
        const mainMapElem = document.getElementById('main-map');
        this.map = new Map(mainMapElem, {
            center,
            zoom: 11,
            mapId: '466f1723671cde5a',
            maxZoom: 18,
            gestureHandling: "greedy"
        });
        this.clusterer = new MarkerClusterer({ map: this.map });
    }

    handleDeviceUpdateOrFilterChange(devices_newUpdate: IDeviceViewModel[]) {
        const bounds = new google.maps.LatLngBounds();

        let doAnyDevicesHaveMapPermissions = false;
        for (const deviceVM of devices_newUpdate) {
            if (deviceVM.device.hasLiveMapPermission) {
                doAnyDevicesHaveMapPermissions = true;
                break;
            }
        }
        const mainMapElem = document.getElementById('main-map');
        if (doAnyDevicesHaveMapPermissions) {
            mainMapElem.style.display = 'unset';
        } else {
            mainMapElem.style.display = 'none';
        }

        devices_newUpdate = devices_newUpdate.filter(
            (deviceVM: IDeviceViewModel) => {
                if (
                    this.filterSettings.filter.showOffline == false &&
                    deviceVM.device.isOnline == false
                ) {
                    return false;
                }
                if (
                    this.filterSettings.filter.showBodycams == false &&
                    deviceVM.device.isBodycam == true
                ) {
                    return false;
                }
                if (
                    this.filterSettings.filter.showVehicles == false &&
                    deviceVM.device.isBodycam == false
                ) {
                    return false;
                }
                if (this.filter.searchTerm !== '') {
                    const searchTerm = this.filter.searchTerm.toLowerCase();

                    if (
                        deviceVM.device.deviceName
                            .toLowerCase()
                            .indexOf(searchTerm) === -1 &&
                        (deviceVM.device.assignedUsername == null ||
                            deviceVM.device.assignedUsername
                                .toLowerCase()
                                .indexOf(searchTerm) === -1)
                    ) {
                        return false;
                    }
                }

                return true;
            }
        );

        devices_newUpdate.sort((aRaw, bRaw) => {
            const a = aRaw.device;
            const b = bRaw.device;

            let dataA: boolean | string = a.isOnline;
            let dataB: boolean | string = b.isOnline;

            let returnValue = 0;

            switch (this.filterSettings.sortField) {
                case 'deviceName':
                    dataA = this.getDeviceDisplayName(a);
                    dataB = this.getDeviceDisplayName(b);
                    break;
                case 'deviceStatus':
                    dataA = a.isOnline;
                    dataB = b.isOnline;
                    break;
                case 'userName':
                    dataA = a.assignedUsername;
                    dataB = b.assignedUsername;
                    break;
            }

            if (dataA < dataB) {
                returnValue = -1;
            }

            if (dataA > dataB) {
                returnValue = 1;
            }

            if (
                dataA == dataB &&
                this.filterSettings.sortField == 'deviceStatus'
            ) {
                const aName = this.getDeviceDisplayName(a);
                const bName = this.getDeviceDisplayName(b);

                if (aName < bName) {
                    return -1;
                }

                if (aName > bName) {
                    return 1;
                }
            }

            if (
                this.filterSettings.sortDirection === 'asc' &&
                returnValue !== 0
            ) {
                returnValue = returnValue * -1;
            }

            if (returnValue === 0) {
                if (a.serialNumber < b.serialNumber) {
                    return -1;
                } else {
                    return 1;
                }
            }

            return returnValue;
        });

        const addedDevices: IDeviceViewModel[] = [];
        const removedDevices: IDeviceViewModel[] = [];
        const updatedDevices: [IDeviceViewModel, IDeviceViewModel][] = [];

        for (const deviceVM of devices_newUpdate) {
            const possibleMatchingDeviceForUpdate =
                this.devices_asOfLastUpdate.find(
                    (x) =>
                        x.device.serialNumber === deviceVM.device.serialNumber
                );
            if (possibleMatchingDeviceForUpdate) {
                updatedDevices.push([
                    possibleMatchingDeviceForUpdate,
                    deviceVM,
                ]);
            } else {
                addedDevices.push(deviceVM);
            }
        }

        for (const deviceVM of this.devices_asOfLastUpdate) {
            if (
                !addedDevices.find(
                    (x) =>
                        x.device.serialNumber === deviceVM.device.serialNumber
                ) &&
                !updatedDevices.find(
                    (x) =>
                        x[0].device.serialNumber ===
                        deviceVM.device.serialNumber
                )
            ) {
                removedDevices.push(deviceVM);
            }
        }

        for (const [oldDeviceVM, newDeviceVM] of updatedDevices) {
            // Keep the last good location around if we had one before.
            if (!newDeviceVM.location && oldDeviceVM.location) {
                newDeviceVM.location = oldDeviceVM.location;
            }
            if (
                oldDeviceVM.device.latitude !== newDeviceVM.device.latitude ||
                oldDeviceVM.device.longitude !== newDeviceVM.device.longitude
            ) {
                oldDeviceVM.device.latitude = newDeviceVM.device.latitude;
                oldDeviceVM.device.longitude = newDeviceVM.device.longitude;

                if (oldDeviceVM.marker) {
                    this.clusterer.removeMarker(oldDeviceVM.marker);
                    oldDeviceVM.marker.position = new google.maps.LatLng(
                        newDeviceVM.device.latitude,
                        newDeviceVM.device.longitude
                    );
                    this.clusterer.addMarker(oldDeviceVM.marker);
                }
            }

            // todo because of this, the cameras object is never invalidated but maybe that's fine since it's set out-of-band?
            // todo redo how we are integrating device details responses to be less confusing
            if (oldDeviceVM.cameras) {
                newDeviceVM.cameras = oldDeviceVM.cameras;
            }

            newDeviceVM.marker = oldDeviceVM.marker;
            if (
                newDeviceVM.device.hasValidLocation !==
                oldDeviceVM.device.hasValidLocation
            ) {
                oldDeviceVM.basicContent.remove();
                oldDeviceVM.detailsContent.remove();
                newDeviceVM.basicContent = this.buildBasicContent(newDeviceVM);
                newDeviceVM.detailsContent =
                    this.buildDetailsContent(newDeviceVM);
            } else {
                newDeviceVM.basicContent = oldDeviceVM.basicContent;
                newDeviceVM.detailsContent = oldDeviceVM.detailsContent;
            }
            newDeviceVM.isCollapsed = oldDeviceVM.isCollapsed;

            this.updateContent(newDeviceVM);
        }

        for (const deviceVM of addedDevices) {
            if (deviceVM.device.hasLiveMapPermission) {
                deviceVM.basicContent = this.buildBasicContent(deviceVM);
                deviceVM.detailsContent = this.buildDetailsContent(deviceVM);
                const newMarker = new google.maps.marker.AdvancedMarkerElement({
                    position: new google.maps.LatLng(
                        deviceVM.device.latitude,
                        deviceVM.device.longitude
                    ),
                    //map: this.mainMap,
                    content: deviceVM.isCollapsed
                        ? deviceVM.basicContent
                        : deviceVM.detailsContent,
                });

                newMarker.addListener('click', () => {
                    this.devices_asOfLastUpdate
                        .filter(
                            (x) =>
                                x.device.serialNumber !==
                                deviceVM.device.serialNumber
                        )
                        .forEach((x) => {
                            x.isCollapsed = true;
                            this.updateContent(x);
                        });
                    const myVm = this.devices_asOfLastUpdate.find(
                        (x) =>
                            x.device.serialNumber ===
                            deviceVM.device.serialNumber
                    );
                    if (myVm) {
                        myVm.isCollapsed = false;
                        this.updateContent(myVm);
                    }
                });

                this.clusterer.addMarker(newMarker);
                // todo this might need to be limited to only initial load
                bounds.extend(newMarker.position);
                deviceVM.marker = newMarker;
            }
        }

        for (const deviceVM of removedDevices) {
            if (deviceVM.marker) {
                this.clusterer.removeMarker(deviceVM.marker);
            }
        }

        this.devices_asOfLastUpdate = devices_newUpdate;

        if (this.state_hasCompletedAtLeastOneUpdateDisplay === false) {
            this.map.fitBounds(bounds);
        }
    }

    onSeachOrFilterChangeCallback() {
        window.dispatchEvent(new CustomEvent('search or filter change'));
    }

    formatAddress(device: IDeviceData) {
        if (this.filterSettings.addressDisplay === 'street') {
            return `${device.address}`;
        }

        return `${device.city}, ${device.state}`;
    }

    openInactivityDialog() {
        const dialogRef = this.dialog.open(LiveInactivityDialogComponent, {});
        dialogRef.afterClosed().subscribe(() => {
            window.dispatchEvent(
                new CustomEvent('reconnecting after inactive')
            );
        });
    }

    openFilterDialog(): void {
        const dialogRef = this.dialog.open(FilterDialog, {
            data: {
                sortField: this.filterSettings.sortField,
                sortDirection: this.filterSettings.sortDirection,
                addressDisplay: this.filterSettings.addressDisplay,
                filter: {
                    showOffline: this.filterSettings.filter.showOffline,
                    showBodycams: this.filterSettings.filter.showBodycams,
                    showVehicles: this.filterSettings.filter.showVehicles,
                },
            },
        });

        dialogRef.afterClosed().subscribe((result) => {
            if (result) {
                this.filterSettings.sortField = result.sortField;
                this.filterSettings.sortDirection = result.sortDirection;
                this.filterSettings.addressDisplay = result.addressDisplay;
                this.filterSettings.filter.showOffline =
                    result.filter.showOffline;
                this.filterSettings.filter.showBodycams =
                    result.filter.showBodycams;
                this.filterSettings.filter.showVehicles =
                    result.filter.showVehicles;

                // todo move filter settings to the event parameters
                window.dispatchEvent(
                    new CustomEvent('search or filter change')
                );
            }
        });
    }

    getDeviceDisplayName(device: IDeviceData) {
        if (!device.assignedUsername) {
            if (device.isBodycam) {
                return device.serialNumber + ' (No User)';
            } else {
                return device.deviceName + ' (No User)';
            }
        }

        if (device.firstName && device.lastName) {
            return `${device.firstName} ${device.lastName}`;
        }

        if (device.assignedUsername) {
            return device.assignedUsername;
        }

        return device.deviceName;
    }

    timeAgo(input) {
        if (input === null || input === undefined) {
            return 'never';
        }

        const date = input instanceof Date ? input : new Date(input);
        const formatter = new Intl.RelativeTimeFormat('en');
        const ranges = [
            ['years', 3600 * 24 * 365],
            ['months', 3600 * 24 * 30],
            ['weeks', 3600 * 24 * 7],
            ['days', 3600 * 24],
            ['hours', 3600],
            ['minutes', 60],
            ['seconds', 1],
        ] as const;
        const secondsElapsed = (date.getTime() - Date.now()) / 1000;

        for (const [rangeType, rangeVal] of ranges) {
            if (rangeVal < Math.abs(secondsElapsed)) {
                const delta = secondsElapsed / rangeVal;
                return formatter.format(Math.round(delta), rangeType);
            }
        }
    }

    zoomToDevice(event: any, device: IDeviceData) {
        event.stopPropagation();
        const targetLatLong = new google.maps.LatLng(
            device.latitude,
            device.longitude
        );
        const bounds = new google.maps.LatLngBounds();
        bounds.extend(targetLatLong);
        this.map.fitBounds(bounds);
    }

    launchLiveView(deviceId: string, cameras?: IDeviceCameraViewModel[]) {
        this.dialog.open(LiveDownloadDialogComponent, {});

        let cameraFragment  = "";

        if (cameras && cameras.length > 0) {
            const quality = cameras.length === 1 ? 'HD' : 'VGA';
            for (const camera of cameras) {
                cameraFragment += `/${camera.index}${quality}`;
            }
        }

        this.apiService
            .devices_GetDeviceLiveViewLink(deviceId)
            .toPromise()
            .then((res) => {
                window.location.href =
                    'pv-live://' +
                    res.url +
                    '/' +
                    res.authToken +
                    '/' +
                    res.liveViewUID +
                    cameraFragment;
            });
    }

    popupCameraSelectForLiveView(event: any, deviceVM: IDeviceViewModel) {
        event.stopPropagation();
        event.preventDefault();
        const dialogRef = this.dialog.open(LiveCameraSelectDialogComponent, {
            data: {
                deviceVM,
            },
        });

        dialogRef.afterClosed().subscribe((selectedCameras) => {
            this.launchLiveView(deviceVM.device.deviceId, selectedCameras);
        });
    }

    resetZoom() {
        const bounds = new google.maps.LatLngBounds();

        this.devices_asOfLastUpdate.forEach((deviceVM: IDeviceViewModel) => {
            bounds.extend(
                new google.maps.LatLng(
                    deviceVM.device.latitude,
                    deviceVM.device.longitude
                )
            );
        });

        this.map.fitBounds(bounds);
    }

    createDeviceVMForDevice(device: IDeviceData) {
        const result: IDeviceViewModel = {
            device,
            marker: null,
            markerElement: null,
            isCollapsed: true,
            location: null,
            basicContent: null,
            detailsContent: null,
            cameras: null,
        };

        // Technically, this doesn't handle missing just lat or just long, but that's too unlikely right now.
        if (
            (!result.device.latitude && !result.device.longitude) ||
            (result.device.latitude &&
                result.device.latitude === 0 &&
                result.device.longitude &&
                result.device.longitude === 0)
        ) {
            result.device.latitude = this.defaultLocationLat;
            result.device.longitude = this.defaultLocationLong;
        }

        return result;
    }

    toggleHighlight(markerView) {
        if (markerView.content.classList.contains('highlight')) {
            markerView.content.classList.remove('highlight');
            markerView.zIndex = null;
        } else {
            markerView.content.classList.add('highlight');
            markerView.zIndex = 1;
        }
    }

    updateContent(deviceVM: IDeviceViewModel) {
        if (!deviceVM.marker) {
            return;
        }

        if (deviceVM.isCollapsed) {
            deviceVM.marker.content = deviceVM.basicContent;
        } else {
            this.conn.invoke('GetDeviceDetails', deviceVM.device.serialNumber);
            this.devices_asOfLastUpdate
                .filter(
                    (x) =>
                        x.device.serialNumber !== deviceVM.device.serialNumber
                )
                .forEach((x) => {
                    if (x.marker) {
                        x.marker.zIndex = null;
                    }
                });
            deviceVM.marker.zIndex = 1;

            const targetElementContainer: HTMLDivElement =
                deviceVM.detailsContent.querySelector(
                    `[id='${deviceVM.device.serialNumber}_location_container']`
                );
            if (deviceVM.location) {
                const targetElement: HTMLDivElement =
                    deviceVM.detailsContent.querySelector(
                        `[id='${deviceVM.device.serialNumber}_location_contents']`
                    );
                targetElementContainer.style.display = 'flex';
                targetElement.textContent = deviceVM.location;
            } else {
                targetElementContainer.style.display = 'none';
            }
            deviceVM.marker.content = deviceVM.detailsContent;
        }

        // if (deviceVM.device.isOnline) {
        //     contentRoot.classList.remove('offline');
        //     contentRoot.classList.add('online');
        // } else {
        //     contentRoot.classList.remove('online');
        //     contentRoot.classList.add('offline');
        // }
    }

    buildBasicContent(deviceVM: IDeviceViewModel) {
        const content = document.createElement('div');
        content.id = `${deviceVM.device.serialNumber}_marker_content_root`;
        content.classList.add('live-marker');
        content.classList.add(
            deviceVM.device.isOnline && deviceVM.device.hasValidLocation
                ? 'online'
                : 'offline'
        );
        content.innerHTML = `
        <div class="live-basic">
            <span class="material-icons device-type-icon">${
                deviceVM.device.isBodycam ? 'person' : 'local_taxi'
            }</span>
            <span>${this.getDeviceDisplayName(deviceVM.device)}</span>
        </div>
        `;
        deviceVM.basicContent = content;

        return content;
    }

    buildDetailsContent(deviceVM: IDeviceViewModel) {
        const content = document.createElement('div');
        content.classList.add('live-marker');
        content.classList.add(
            deviceVM.device.isOnline && deviceVM.device.hasValidLocation
                ? 'online'
                : 'offline'
        );
        content.innerHTML = `
        <div class="live-basic">
            <span class="material-icons device-type-icon">${
                deviceVM.device.isBodycam ? 'person' : 'local_taxi'
            }</span>
            <span>${this.getDeviceDisplayName(deviceVM.device)}</span>
        </div>

        <div class="live-details">
            <div style="display: flex; align-items: center; padding: 0 5px;">
                <span style="flex: 1 1 auto; padding: 4px;">${
                    deviceVM.device.isBodycam ? 'Device: ' : 'Unit: '
                } </span>
                <span style="padding: 4px 8px; font-size: 12px;">${
                    deviceVM.device.isBodycam
                        ? deviceVM.device.serialNumber
                        : deviceVM.device.deviceName
                }</span>
            </div>
            <div style="display: flex; align-items: center; padding: 0 5px;">
                <span style="flex: 1 1 auto; padding: 4px;">Device Type: </span>
                <span style="padding: 4px 8px; font-size: 12px;">${
                    deviceVM.device.deviceTypeName
                }</span>
            </div>
            <div id="${
                deviceVM.device.serialNumber
            }_location_container" style="display: none; align-items: center; padding: 0 5px;">
                <span style="flex: 1 1 auto; padding: 4px;">Location: </span>
                <span id="${
                    deviceVM.device.serialNumber
                }_location_contents" style="padding: 4px 8px; font-size: 12px;">tbd</span>
            </div>
            ${
                deviceVM.device.isOnline === false
                    ? `
                    <div style="display: flex; align-items: center; padding: 0 5px;">
                        <span style="flex: 1 1 auto; padding: 4px;">Last Connected: </span>
                        <span style="padding: 4px 8px; font-size: 12px;">${this.timeAgo(
                            deviceVM.device.lastConnected
                        )}</span>
                    </div>`
                    : ``
            }
            <div style="display: flex; align-items: center; justify-content:flex-end; padding: 0 5px;">
                ${
                    deviceVM.device.hasLiveViewPermission
                        ? `<button class="live-view-button" data-deviceid="${deviceVM.device.deviceId}">
                    <div class="material-icons map-marker-button-icon">videocam</div>
                    <span>View Live</span>
                </button>`
                        : ``
                }
                <button style="margin-left: 10px;" onclick="window.handleRetrieveVideo('${
                    deviceVM.device.deviceId
                }')">
                    <div class="material-icons map-marker-button-icon">video_file</div>
                    <span>Request Video</span>
                </button>
            </div>
        </div>
        `;

        const liveButton = content.getElementsByClassName('live-view-button');
        if (liveButton.length > 0) {
            liveButton[0].addEventListener('click', () => {
                this.launchLiveView(deviceVM.device.deviceId);
            });
        }

        deviceVM.detailsContent = content;
        return content;
    }

    handleRequestVideo(event: MouseEvent | null, device: IDeviceViewModel) {
        if (event) {
            event.stopPropagation();
        }
        const dialogRef = this.dialog.open(CreateRequestDialogComponent, {
            data: {
                id: device.device.deviceId,
                deviceName: device.device.deviceName,
                deviceSerial: device.device.serialNumber,
            },
        });
        dialogRef.afterClosed().subscribe(() => {});
    }

    handlePanelClick(device: IDeviceViewModel) {
        this.devices_asOfLastUpdate
            .filter((x) => x.device.serialNumber !== device.device.serialNumber)
            .forEach((x) => {
                x.isCollapsed = true;
                this.updateContent(x);
            });
        device.isCollapsed = !device.isCollapsed;
        this.updateContent(device);
    }

    panelTrackBy(index: number, item: IDeviceViewModel) {
        return item.device.serialNumber;
    }
}
