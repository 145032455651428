<mat-toolbar class="navbar">
    <div class="logo-container d-flex align-items-center">
        <div class="">
            <a [routerLink]="logoLink"><img height="40" src="/assets/images/SecuraMax_Logo_RGB.svg"/></a>
        </div>
        <ng-container *ngIf="isReadyForRender && userService.hasAccessToLive()">
            <div class="site-toggle-1" (click)="siteToggle_click()">
                <div class="site-toggle-base">
                    <div class="site-toggle-item-1">
                        <span>Review</span>
                        <div *ngIf="!router.url.startsWith('/live')" class="site-toggle-item-overlay">
                            <span>Review</span>
                        </div>
                    </div>

                    <div class="site-toggle-item-1">
                        <span>Live</span>
                        <div *ngIf="router.url.startsWith('/live')" class="site-toggle-item-overlay">
                            <span>Live</span>
                        </div>
                    </div>
                </div>
            </div>
        </ng-container>
    </div>

    <div class="search-container" *ngIf="route.root.firstChild.routeConfig.path !== 'live'">
        <app-searchbar [startingText]="documentSearchService.currentSearchFilter.value.searchTerm" (search)="onSearch($event)" placeholder="Document Search" isPrimary="true" style="margin-left: 14px;"></app-searchbar>
    </div>

    <div class="buttons-container">


        <div class="menu-container">

            <button *ngIf="route.root.firstChild.routeConfig.path !== 'live'" mat-button [matMenuTriggerFor]="reviewMenu" i18n>
                <div class="menu-button-1">
                    <div>Review</div>
                    <mat-icon class="menu-button-arrow">arrow_drop_down</mat-icon>
                </div>
            </button>
            <mat-menu #reviewMenu="matMenu">
                <button mat-menu-item routerLink="/annotate2" i18n>Review Uploaded Documents</button>
                <button mat-menu-item routerLink="/review/autoreviewrules/list" i18n>Review By Time</button>
            </mat-menu>
            <a *ngIf="route.root.firstChild.routeConfig.path !== 'live'" mat-button routerLink="/cases" i18n>Cases</a>
            <a *ngIf="userService.hasPermission('upload_directly') && route.root.firstChild.routeConfig.path !== 'live'" mat-button routerLink="/upload" i18n>Upload</a>
            <button *ngIf="userService.hasPermission('manage_devices') || userService.hasPermission('view_report_pending_deletion') || userService.hasPermission('view_report_device_usage') || userService.hasPermission('manage_site_settings') || userService.hasPermission('manage_users') || userService.hasPermission('manage_groups') || userService.hasPermission('manage_upload_stations') || userService.hasPermission('manage_categories') || userService.hasPermission('manage_tags')"
                    mat-button [matMenuTriggerFor]="adminMenu" i18n>
                <div class="menu-button-1">
                    <div>Admin</div>
                    <mat-icon class="menu-button-arrow">arrow_drop_down</mat-icon>
                </div>
            </button>
            <mat-menu #adminMenu="matMenu">
                <button *ngIf="userService.hasPermission('manage_devices')" mat-menu-item
                        routerLink="/admin/devices/list" i18n>Devices
                </button>
                <button *ngIf="userService.hasPermission('manage_users')" mat-menu-item routerLink="/admin/users/list"
                        i18n>Users
                    / Permissions
                </button>
                <button *ngIf="userService.hasPermission('manage_upload_stations')" mat-menu-item
                        routerLink="/admin/uploadstations" i18n>Upload Stations
                </button>
                <button
                    *ngIf="userService.hasPermission('manage_categories')"
                    mat-menu-item routerLink="/admin/categories" i18n>Categories
                </button>
                <button
                    *ngIf="userService.hasPermission('manage_tags')"
                    mat-menu-item routerLink="/admin/tags" i18n>Tags
                </button>
                <button
                    *ngIf="userService.hasPermission('view_report_pending_deletion') || userService.hasPermission('view_report_device_usage')"
                    mat-menu-item routerLink="/admin/reports/pendingdeletion" i18n>Reports
                </button>
                <button *ngIf="userService.hasPermission('manage_site_settings')" routerLink="/admin/settings"
                        mat-menu-item i18n>Site Settings
                </button>
                <!--<button *ngIf="userService.hasPermission('manage_site_settings')" routerLink="/admin/apisettings"
                    mat-menu-item i18n>API Settings</button>-->
            </mat-menu>
        </div>
    </div>



    <div class="user-container">
        <button mat-button [matMenuTriggerFor]="profileMenu">
            <div class="d-flex justify-content-between">
                <div class="mr-3">{{user?.firstName}} {{user?.lastName}}</div>
                <div class="letter-icon-1">
                    <div class="letter-icon">
                        <mat-icon>person</mat-icon>
                    </div>
                </div>
            </div>
        </button>
        <mat-menu #profileMenu="matMenu">
            <button mat-menu-item routerLink="/profile" i18n>
                <mat-icon>info_outline</mat-icon>
                Profile
            </button>
            <a mat-menu-item href="/Auth/Logout" i18n>
                <mat-icon>exit_to_app</mat-icon>
                Log Out
            </a>
            <div mat-menu-item>
                <mat-icon>info_outline</mat-icon>
                v 3.4.6
            </div>
            <button mat-menu-item routerLink="/software" i18n>
                <mat-icon>file_download</mat-icon>
                Software Downloads
            </button>
            <a mat-menu-item href="https://support.provisionusa.com/hc/en-us/sections/18123504404621-SecuraMax" target="_blank" i18n>
                <mat-icon>help</mat-icon>
                Help
            </a>
        </mat-menu>
    </div>

</mat-toolbar>

<div id="site_content_container" *ngIf="isReadyForRender">
    <router-outlet></router-outlet>



    <div *ngIf="router.url === '/'" id="help_buttons_container" class="help-popups-container-container" #help_buttons_container>
        <a href="https://support.provisionusa.com/hc/en-us#contact-boxes" target="_blank">
            <mat-icon>phone</mat-icon>
        </a>
        <a href="https://support.provisionusa.com/hc/en-us/sections/18123504404621-SecuraMax" target="_blank">
            <mat-icon>help</mat-icon>
        </a>
    </div>
</div>
