import {Component} from '@angular/core';
import {CasesDetailsViewModelExtension} from "../../../models/cases-details-viewmodel.model";
import {documentStatus} from "../../../shared/helpers/document-status-const";
import {SecuraMaxApiService, ShareMultipleViewModel, ShareViewModel} from "../../../services/api/securamaxapi.service";
import {ApiService} from "../../../services/api.service";
import {TitleService} from "../../../services/title.service";
import {ActivatedRoute} from "@angular/router";
import {CaseDocumentListViewModelExtension} from "../../../models/cases-details-documents-viewmodel.model";
import {SnackbarService} from "../../../services/snackbar.service";
import {FormBuilder, FormControl, FormGroup} from "@angular/forms";
import {
    CasesDetailsShareDialogComponent,
    ShareDialogData, shareUserType
} from "../cases-details-share-dialog/cases-details-share-dialog.component";
import {UserSelectModel} from "../../../models/user-select.model";
import {MatDialog} from "@angular/material/dialog";
import {
    CasesDownloadMultipleArchiveWarningComponent
} from "../cases-download-multiple-archive-warning/cases-download-multiple-archive-warning.component";


interface CaseDetails2ComponentForm {
    selectedDocuments: FormControl<CaseDocumentListViewModelExtension[]>;

}


@Component({
    selector: 'app-cases-details2',
    templateUrl: './cases-details2.component.html',
    styleUrls: ['./cases-details2.component.css']
})
export class CasesDetails2Component {

    datasource: CasesDetailsViewModelExtension = new CasesDetailsViewModelExtension();
    private documentsLoaded: boolean;
    private id: number;

    public form: FormGroup<CaseDetails2ComponentForm>;
    private isSavingShareChanges: boolean;
    private shareExpireDateTime: any;
    private shareCanView: any;
    private shareCanEditMetadata: any;
    private shareCanDownload: any;
    private shareCanReShare: any;

    constructor(
        private fb: FormBuilder,
        private route: ActivatedRoute,
        private apiService: ApiService,
        private titleService: TitleService,
        private securaMaxApiService: SecuraMaxApiService,
        public dialog: MatDialog,
        private toastr: SnackbarService,

    ) {
        this.id = Number(this.route.snapshot.paramMap.get('id'));
        this.form = fb.group({
            selectedDocuments: [[] as CaseDocumentListViewModelExtension[]]
        });
    }

    ngOnInit() {
        this.titleService.setTitle('Case Details');
        this.securaMaxApiService.cases_Details(this.id).subscribe(data => {
            this.titleService.setTitle('Case Details');
            this.documentsLoaded = true;
            this.datasource = data;
            this.datasource.documents = this.TransformResponse(this.datasource.documents);
        }, (err) => {
            this.toastr.error("An error occurred while loading the case.");
        });
        this.form.valueChanges.subscribe(x => {
        })
    }


    private TransformResponse(docuResponse: CaseDocumentListViewModelExtension[]) {
        let docs = [];

        for (let doc of docuResponse) {
            if (doc.statusId === 2) {
                doc.isDeleted = true;
            } else {
                doc.isDeleted = false;
            }
        }

        for (var i = 0; i < docuResponse.length; i++) {
            if (docuResponse[i].parentId == null) {
                docs.push(docuResponse[i]);
            }
        }

        for (var i = 0; i < docuResponse.length; i++) {
            if (docuResponse[i].parentId != null) {
                var parentFound = false;
                for (var j = 0; j < docs.length; j++) {
                    if (docs[j].id === docuResponse[i].parentId) {
                        if (docs[j].children === undefined) {
                            docs[j].children = [];
                        }
                        docs[j].children.push(docuResponse[i]);
                        parentFound = true;
                    }
                }

                if (parentFound === false) {
                    docs.push(docuResponse[i]);
                }
            }
        }

        return docs;
    }


    edit_click() {

    }

    protected readonly documentStatus = documentStatus;

    AuditCase(id: number) {
        this.apiService.downloadCaseAuditReport(id.toString()).subscribe();
    }

    selectAll_click() {
        this.form.controls.selectedDocuments.setValue(this.datasource.documents.filter(x => !x.isDeleted));
    }

    deselectAll_click() {
        this.form.controls.selectedDocuments.setValue([]);
    }

    shareSelected_click() {
        const dialogRef = this.dialog.open(CasesDetailsShareDialogComponent, {
            width: '500px',
            data: {
                shareSelect: new UserSelectModel(),
                expireDateTime: this.shareExpireDateTime,
                canView: this.shareCanView,
                canEditMetadata: this.shareCanEditMetadata,
                canDownload: this.shareCanDownload,
                canReShare: this.shareCanReShare,
                shareMinDate: new Date()
            }
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result === undefined || result === '') {
                return;
            }

            this.ShareDocument(result);
        });

    }

    private form_selectedDocuments_getFlattened(): CaseDocumentListViewModelExtension[] {
        let docs: CaseDocumentListViewModelExtension[] = [];

        this.form.controls.selectedDocuments.value.forEach(d => {
            docs.push(d);

            if ((d.children?.length ?? 0) > 0) {
                d.children.forEach(cd => docs.push(cd));
            }
        });

        return docs;
    }


    ShareDocument(shareInformation: ShareDialogData) {
        let checkedDocuments = this.form.controls.selectedDocuments.value;
        let checkedDocumentIds = checkedDocuments.map((x)=>{ return x.id; });


        var shareMultiple = new ShareMultipleViewModel();
        shareMultiple.documentIds = checkedDocumentIds;

        shareMultiple.shareViewModel = new ShareViewModel();
        shareMultiple.shareViewModel.isUser = shareInformation.shareUserType == shareUserType.exisitingUser;
        if (shareInformation.shareUserType === shareUserType.exisitingUser) {
            shareMultiple.shareViewModel.sharedWithUserId = shareInformation.shareSelect.associatedUserId;
            shareMultiple.shareViewModel.email = shareInformation.shareSelect.associatedUserEmail;
        } else {
            shareMultiple.shareViewModel.email = shareInformation.newShareUserInput;
        }

        shareMultiple.shareViewModel.canAddMetaData = shareInformation.canEditMetadata;
        shareMultiple.shareViewModel.canReshare = shareInformation.canReShare;
        shareMultiple.shareViewModel.canDownload = shareInformation.canDownload;
        shareMultiple.shareViewModel.expires = shareInformation.expireDateTime;
        shareMultiple.shareViewModel.emailSubject = shareInformation.shareSubject.replace(/ /g, "") ? shareInformation.shareSubject : null;

        this.isSavingShareChanges = true;
        this.securaMaxApiService.share_PostMultipleShares(this.id, shareMultiple).subscribe((data) => {

            this.toastr.success('Share(s) successful');
            this.isSavingShareChanges = false;

            shareMultiple.documentIds.forEach(element => {
                var existingShare = this.datasource.documents.filter(x => x.id == element)[0].shares.find(x => x.email === shareMultiple.shareViewModel.email);
                if (existingShare !== undefined)
                {
                    this.toastr.success('The existing share to ' + shareMultiple.shareViewModel.email + ' was updated and a new email was sent.');
                    this.datasource.documents.filter(x => x.id == element)[0].shares[this.datasource.documents.filter(x => x.id == element)[0].shares.indexOf(existingShare)] = shareMultiple.shareViewModel;
                }
                else
                {
                    this.datasource.documents.filter(x => x.id == element)[0].shares.push(shareMultiple.shareViewModel);
                }
            });
        }, err => {
            this.toastr.error(err.response)
            this.isSavingShareChanges = false;
        })
    }


    DownloadMultiples(files) {
        if (files.length === 0) { return; }
        var file = files.pop();

        this.apiService.downloadDocument(file.id).subscribe(url => {
            // Known issue: calling window.open() multiple times in quick sucession is blocked by modern browsers.
            window.open(url);
        });

        this.DownloadMultiples(files);
    }



    downloadSelected_click() {
        let documents = this.form_selectedDocuments_getFlattened();

        let archivedDocuments = documents.filter(d => d.statusId === documentStatus.archived);

        if (documents.filter(d => d.statusId === documentStatus.archived).length > 0) {
            //There was an archived document selected, open the dialog to warn that only non archived documents will download
            const dialogRef = this.dialog.open(CasesDownloadMultipleArchiveWarningComponent, {
                width: '400px',
                data: {
                    totalDocumentCount: documents.length,
                    totalArchivedDocumentsCount : archivedDocuments.length
                }
            });

            dialogRef.afterClosed().subscribe(result => {
                if (result === '') {
                    return;
                }

                this.DownloadMultiples(documents);
            });
        }
        else
        {
            this.DownloadMultiples(documents);
        }

    }
}
