<div class="card mt-3">
    <div class="documents-search-body card-body">
        <h2 class="card-title" style="grid-column: 1/-1" i18n>
            Document Search
        </h2>
        <div class="sidebar-container">
            <form [formGroup]="filterOptionsForm">
                <div class="filter-header-container">
                    <h3>Filter by</h3>
                    <div class="filter-header-button-container">
                        <button mat-button class="smx-secondary-button mini" (click)="clear_click()">Clear</button>
                    </div>
                </div>
                <div class="filter-settings-container">
                    <div class="label-and-control-container">
                        <label>Name or Email</label>
                        <app-user-select [(userId)]="filterOptionsForm_userId" [(associatedUserName)]="filterOptionsForm_userName"
                                         (change)="userSelect_change()" [userSelectName]="userSelectName"></app-user-select>
                    </div>
                    <div class="label-and-control-container">
                        <label>Groups</label>
                        <mat-form-field class="ca80" appearance="outline">
                            <mat-select formControlName="groups" multiple name="selectedGroups">
                                <mat-option *ngFor="let group of filterOptionsForm_groupsList"
                                            [value]="group.name"> {{group.name}}</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="label-and-control-container">
                        <label>Date and Time Range</label>
                        <app-daterangepicker #dateRangePicker (change)="daterangepicker_change($event)" [(start)]="filterOptionsForm_start" [(end)]="filterOptionsForm_end"></app-daterangepicker>
                    </div>
                    <div class="label-and-control-container">
                        <label>Status</label>
                        <mat-form-field appearance="outline">
                            <mat-select formControlName="statuses" multiple name="selectedStatuses">
                                <mat-option *ngFor="let status of filterOptionsForm_statusList"
                                            [value]="status.statusId.toString()">{{status.name}}</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="label-and-control-container">
                        <label>Categories</label>
                        <mat-form-field appearance="outline">
                            <mat-select formControlName="categories" multiple name="selectedCategories">
                                <mat-option *ngFor="let category of filterOptionsForm_categoryList"
                                            [value]="category.name"> {{category.name}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <ul class="list-unstyled">
                        <li>
                            <mat-checkbox i18n formControlName="sharedWithAnyoneOnly">Shared Files</mat-checkbox>
                        </li>
                        <li>
                            <mat-checkbox i18n formControlName="sharedWithMeOnly">Shared With Me</mat-checkbox>
                        </li>
                        <li>
                            <mat-checkbox i18n formControlName="sharedByMeOnly">Shared By Me</mat-checkbox>
                        </li>
                        <li>
                            <mat-checkbox i18n formControlName="requireInitialTagging">Documents Awaiting Review
                            </mat-checkbox>
                        </li>
                        <li>
                            <mat-checkbox i18n formControlName="includeDeleted">Include Deleted</mat-checkbox>
                        </li>
                        <li>
                            <mat-checkbox i18n formControlName="importantOnly">Important Only</mat-checkbox>
                        </li>
                        <li>
                            <mat-checkbox i18n formControlName="lockedFromDeletion">Locked From Deletion</mat-checkbox>
                        </li>
                        <li>
                            <mat-checkbox i18n formControlName="unlockedFromDeletion">Unlocked From Deletion
                            </mat-checkbox>
                        </li>
                        <li>
                            <mat-checkbox i18n formControlName="excludeRedactions">Exclude Redactions</mat-checkbox>
                        </li>
                        <li>
                            <mat-checkbox i18n formControlName="onlyRedactions">Redacted Files</mat-checkbox>
                        </li>
                    </ul>
                </div>
            </form>
        </div>
        <app-documents-search-list2 *ngIf="documentSearchService.currentSearchFilter.value.isListView"></app-documents-search-list2>
        <app-documents-search-timeline2 *ngIf="!documentSearchService.currentSearchFilter.value.isListView" ></app-documents-search-timeline2>
    </div>
</div>
