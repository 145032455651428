import { Directive, ElementRef, Input, OnInit } from '@angular/core';
import { FeatureFlagStorageService } from '../services/feature-flags-storage.service';

@Directive({
  selector: '[ifFeature]'
})
export class IfFeatureDirective implements OnInit {
  @Input('ifFeature') featureName!: string;

  constructor(
    private el: ElementRef,
    private featureFlagService: FeatureFlagStorageService
  ) {}

  async ngOnInit() {
    const featureEnabled = await this.featureFlagService.isFeatureEnabled(this.featureName);
    if (!featureEnabled) {
      this.el.nativeElement.style.display = 'none';
    }
  }
}