import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UserSelectModel } from 'src/app/models/user-select.model';
import {
    SecuraMaxApiService,
    ShareViewModel,
} from 'src/app/services/api/securamaxapi.service';

import * as moment from 'moment';
import { SnackbarService } from 'src/app/services/snackbar.service';
import { UserSelectClearService } from 'src/app/services/user/user-select-clear.service';

export enum shareUserType {
    existingUser = 0,
    newUser = 1,
}

export interface IShareCreateDialog2ComponentDialogDataViewModel {

    documentId: string;


}


@Component({
    selector: 'app-share-create-dialog2',
    templateUrl: './share-create-dialog2.component.html',
    styleUrls: ['./share-create-dialog2.component.css'],
})
export class ShareCreateDialog2Component implements OnInit {
    shareMinDate: Date = new Date();
    shareDate: Date;
    shareTime: string;
    daterangepicker_start: string;
    daterangepicker_end: string;

    shareUserType: shareUserType = 0;
    shareSelect: UserSelectModel = new UserSelectModel();
    shareUserSelectName: string = 'shareUserSelect';
    shareSaveDisabled = true;
    newShareUserInput: string = '';
    isSavingShareChanges = false;
    shareCanDownload: boolean = false;
    shareCanAddMetadata: boolean = false;
    shareCanReshare: boolean = false;
    shares: ShareViewModel[] = [];
    shareSubject?: string = '';

    constructor(
        private dialogRef: MatDialogRef<ShareCreateDialog2Component>,
        private apiService: SecuraMaxApiService,
        private toastr: SnackbarService,
        private userSelectClearService: UserSelectClearService,
        @Inject(MAT_DIALOG_DATA) public data: IShareCreateDialog2ComponentDialogDataViewModel
    ) {}

    ngOnInit(): void {
        this.shareButtonAllowed();
        this.apiService
            .share_GetAll(this.data.documentId)
            .toPromise()
            .then(
                (data) => {
                    this.shares = data;
                },
                () => {
                    this.toastr.error(
                        'An error occurred while getting document shares.'
                    );
                }
            );
    }

    onClose() {
        this.dialogRef.close(this.data);
    }

    daterangepicker_change() {
        this.shareDate = moment(this.daterangepicker_start).toDate();
        this.shareTime = moment(this.daterangepicker_start).format('HH:mm');
        this.shareButtonAllowed();
    }

    shareButtonAllowed() {
        this.shareSaveDisabled = false;

        if (this.shareUserType == 0) {
            if (Object.keys(this.shareSelect).length === 0) {
                this.shareSaveDisabled = true;
                return;
            }
        } else {
            if (
                this.newShareUserInput == 'undefined' ||
                this.newShareUserInput === ''
            ) {
                this.shareSaveDisabled = true;
                return;
            }
        }

        if (this.shareDate == null) {
            this.shareSaveDisabled = true;
            return;
        }

        if (this.shareTime == null || this.shareTime === '') {
            this.shareSaveDisabled = true;
            return;
        }

        if (moment(this.shareDate).date() === moment().date()) {
            if (moment(this.shareTime, 'HH:mm').isBefore(moment())) {
                this.shareSaveDisabled = true;
                return;
            }
        }
        return;
    }

    validateShare() {
        if (this.shareUserType == 0) {
            if (Object.keys(this.shareSelect).length === 0) {
                this.toastr.error('User must be selected to share.');
                return false;
            }
        } else {
            if (
                this.newShareUserInput == 'undefined' ||
                this.newShareUserInput === ''
            ) {
                this.toastr.error('Email address required for share.');

                return false;
            }
        }

        if (this.shareDate == null) {
            this.toastr.error('Date required for share.');
            return false;
        }

        if (this.shareTime == null || this.shareTime === '') {
            this.toastr.error('Time required for share.');
            return false;
        }

        if (moment(this.shareDate).date() === moment().date()) {
            if (moment(this.shareTime, 'HH:mm').isBefore(moment())) {
                this.toastr.error('Time must be after now.');
                return false;
            }
        }

        return true;
    }

    clearShareInputs() {
        this.shareUserType = shareUserType.existingUser;
        this.userSelectClearService.announceUserClear(this.shareUserSelectName);
        this.shareSelect.associatedUser = '';
        this.shareSelect.associatedUserEmail = '';
        this.shareSelect.associatedUserId = null;
        this.newShareUserInput = '';
        this.shareDate = undefined;
        this.shareTime = undefined;
        this.shareCanDownload = false;
        this.shareCanAddMetadata = false;
        this.shareCanReshare = false;
        this.shareSubject = '';
    }

    saveShare() {
        if (!this.validateShare()) return;

        const shareToSave = new ShareViewModel();

        if (this.shareUserType == shareUserType.existingUser)
            shareToSave.email = this.shareSelect.associatedUserEmail;
        else shareToSave.email = this.newShareUserInput;

        const timeAsMoment = moment(this.shareTime, 'HH:mm');
        const combinedExpires = moment(this.shareDate).set({
            hour: timeAsMoment.get('hour'),
            minute: timeAsMoment.get('minute'),
            second: 0,
            millisecond: 0,
        });
        shareToSave.expires = combinedExpires.utc().toDate();
        shareToSave.canDownload = this.shareCanDownload;
        shareToSave.canAddMetaData = this.shareCanAddMetadata;
        shareToSave.canReshare = this.shareCanReshare;
        shareToSave.emailSubject = this.shareSubject.replace(/ /g, "") ? this.shareSubject : null;

        this.isSavingShareChanges = true;

        this.toastr.success('Creating share.');

        this.apiService.share_Post(this.data.documentId, 0, shareToSave).subscribe(
            (data) => {
                if (data.email.includes('@')) {
                    data.displayName = data.email; //Overwrite display name with email for shares as they are added, see #2492
                } else {
                    data.displayName = this.shareSelect.associatedUserEmail; //if shared with existing user, data.email is just display name
                }
                const foundShare = this.shares.find(
                    (x) => x.email === data.email
                );
                if (foundShare === undefined) {
                    this.shares.push(data);
                    this.toastr.success('New share created.');
                } else {
                    this.toastr.success(
                        `The existing share to ${foundShare.email} was updated.`
                    );
                    this.shares[this.shares.indexOf(foundShare)] = data;
                }
                this.clearShareInputs();
                this.shareButtonAllowed();

                this.dialogRef.close();
                this.isSavingShareChanges = false;
            },
            (err) => {
                if (
                    err.response ===
                    'You cannot share a document with yourself.'
                ) {
                    this.toastr.error(err.response);
                } else {
                    this.toastr.error(
                        'An error occurred while sharing the document.'
                    );
                }

                this.isSavingShareChanges = false;
            }
        );
    }
}
