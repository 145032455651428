<div *ngIf="loading" class="d-flex flex-column justify-content-center align-items-center mt-3">
    <mat-spinner color="accent"></mat-spinner>
    <h1 i18n>Loading...</h1>
</div>

<div class="card mt-2" *ngIf="!loading">
    <div class="ml-3">
        <h1 i18n>Create Redaction</h1>
        <small class="ng-binding" i18n>
            For the best experience, Chrome, Firefox, Edge, or Safari are recommended. Blur
            preview and video quality are not exactly how the output file will look.
        </small>
    </div>
    <div class="d-flex justify-content-center mt-1">
        <div class="col-sm-8">
            <vg-player *ngIf="videoSrcUrl" (onPlayerReady)="onPlayerReady($event)">
                <vg-controls>
                    <vg-play-pause></vg-play-pause>
                    <vg-playback-button></vg-playback-button>
                    <vg-time-display>{{ currentTime }}</vg-time-display>
                    <vg-scrub-bar>
                        <vg-scrub-bar-current-time></vg-scrub-bar-current-time>
                        <!--<vg-cuepoint vg-cuepoints-config="vm.videoConfig.plugins.cuepoints" vg-cuepoints-theme="vm.videoConfig.plugins.cuepoints.theme.url"></vg-cuepoint>-->
                    </vg-scrub-bar>
                    <vg-time-display>{{ timeLeft }}</vg-time-display>
                    <vg-mute></vg-mute>
                    <vg-volume></vg-volume>
                    <vg-fullscreen></vg-fullscreen>
                </vg-controls>
                <vg-buffering></vg-buffering>
                <!-- <vg-overlay-play></vg-overlay-play> -->
                <video [vgMedia]="media" #media id="singleVideo" preload="auto"
                       [ngStyle]="{'-webkit-filter': 'blur(' + blurAmount + 'px);','-moz-filter': 'blur(' + blurAmount + 'px);','-o-filter': 'blur(' + blurAmount + 'px)','-ms-filter': 'blur(' + blurAmount + 'px)','filter': 'blur(' + blurAmount + 'px)'}">
                    <source [src]="videoSrcUrl" type="video/mp4">
                </video>
            </vg-player>
        </div>
    </div>
    <div class="d-flex justify-content-between pl-3 pr-3 mt-1">
        <mat-form-field>
            <mat-label i18n>
                Video Start
            </mat-label>
            <input matInput pattern="[0-9:.]*" (input)="filterTimeInput($event)" [(ngModel)]="formattedVideoTrimStart" (change)="handleVideoTrimStart($event.target.value)">
        </mat-form-field>
        <mat-slider [displayWith]="numToTimespanString.bind(this)" color="accent" class="redaction-slider w-75" min="0" [(max)]="videoTrimMaxEnd" [step]="0.5" discrete>
            <input [(value)]="videoTrimStart" (change)="updateVideoTrim()" matSliderStartThumb>
            <input [(value)]="videoTrimEnd" (change)="updateVideoTrim()" matSliderEndThumb>
        </mat-slider>
        <mat-form-field>
            <mat-label i18n>
                Video End
            </mat-label>
            <input matInput pattern="[0-9:.]*" (input)="filterTimeInput($event)" [(ngModel)]="formattedVideoTrimEnd" (change)="handleVideoTrimEnd($event.target.value)">
        </mat-form-field>
    </div>

    <div class="d-flex justify-content-between pl-3 pr-3 mt-1">
        <mat-form-field>
            <mat-label i18n>
                Audio Start
            </mat-label>
            <input matInput pattern="[0-9:.]*" (input)="filterTimeInput($event)" [(ngModel)]="formattedAudioTrimStart" (change)="handleAudioTrimStart($event.target.value)">
        </mat-form-field>
        <mat-slider [displayWith]="numToTimespanString.bind(this)" color="accent" class="redaction-slider w-75" min="0" [(max)]="audioTrimMaxEnd" step="0.5" discrete>
            <input [(value)]="audioTrimStart" (change)="trimAudioToVideo()" matSliderStartThumb>
            <input [(value)]="audioTrimEnd" (change)="trimAudioToVideo()" matSliderEndThumb>
        </mat-slider>
        <mat-form-field>
            <mat-label i18n>
                Audio End
            </mat-label>
            <input matInput pattern="[0-9:.]*" (input)="filterTimeInput($event)" [(ngModel)]="formattedAudioTrimEnd" (change)="handleAudioTrimEnd($event.target.value)">
        </mat-form-field>

    </div>

    <div class="d-flex justify-content-between pl-3 pr-3 mt-1">
        <mat-form-field>
            <mat-label i18n>
                Blur Amount
            </mat-label>
            <input matInput  (input)="filterBlurInput($event)" type="number" [(ngModel)]="blurAmount">
        </mat-form-field>
        <mat-slider color="accent" class="redaction-slider w-75" min="0" max="40" step="1" discrete>
            <input [(value)]="blurAmount" matSliderThumb>
        </mat-slider>
        <div class="w-25" style="max-width: 212px;">
        </div>
    </div>


    <div class="pl-3 pr-3 mt-1">
        <a mat-button routerLink="/documents/{{documentId}}" class="smx-secondary-button mr-2" i18n>
            <i class="fa fa-arrow-left"></i> Back to Document
        </a>
        <button mat-button class="submit-button smx-primary-button" (click)="submit()" [disabled]="saving"
                aria-label="Submit" i18n>
            Submit
        </button>
    </div>
</div>
