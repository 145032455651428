<h2 mat-dialog-title>Create Share</h2>
<mat-dialog-content>
    <label>Share With</label>
    <mat-radio-group [(ngModel)]="shareUserType" class="mr-3">
        <mat-radio-button [value]="0">
            <div class="text-wrap" (change)="shareButtonAllowed()">
                Existing User
            </div>
        </mat-radio-button>
        <mat-radio-button
            class="ml-2"
            [value]="1"
            (change)="shareButtonAllowed()"
        >
            <div class="text-wrap">New Email</div>
        </mat-radio-button>
    </mat-radio-group>
    <app-user-select
        [(userId)]="shareSelect.associatedUserId"
        [(associatedUserName)]="shareSelect.associatedUser"
        [(userEmail)]="shareSelect.associatedUserEmail"
        [includeCurrentUser]="false"
        [(userSelectName)]="shareUserSelectName"
        *ngIf="shareUserType == 0"
        (change)="shareButtonAllowed()"
    ></app-user-select>

    <div class="w-100">
        <mat-form-field
            appearance="outline"
            *ngIf="shareUserType == 1"
            class="w-100"
        >
            <input
                matInput
                placeholder="Email: john.doe@gmail.com"
                [(ngModel)]="newShareUserInput"
                type="email"
                email
                (change)="shareButtonAllowed()"
            />
        </mat-form-field>
    </div>

    <mat-form-field ifFeature="ShareEmailSubject" appearance="outline" style="width: 100%; margin-top: 10px">
        <mat-label>Subject</mat-label>
        <textarea matInput #subjectInput [(ngModel)]="shareSubject" name="shareSubject" rows="1" maxlength="300" placeholder="Enter email subject"></textarea>
        <mat-hint>{{subjectInput.value?.length || 0}}/300</mat-hint>
    </mat-form-field>

    <div>
        <label>
            <fa-icon icon="calendar">Active User</fa-icon>
            Expires
        </label>
        <br />
        <label>Choose expiration date</label>
        <app-daterangepicker
            #dateRangePicker
            [rangeMode]="false"
            (change)="daterangepicker_change()"
            [(start)]="daterangepicker_start"
            [(end)]="daterangepicker_end"
        ></app-daterangepicker>
    </div>
    <div>
        <label>Permissions</label>
        <div class="checkbox">
            <mat-checkbox [checked]="true" [disabled]="true">
                <div class="text-wrap">View</div>
            </mat-checkbox>
        </div>
        <div class="checkbox">
            <mat-checkbox [(ngModel)]="shareCanDownload">
                <div class="text-wrap">Download</div>
            </mat-checkbox>
        </div>
        <div class="checkbox">
            <mat-checkbox [(ngModel)]="shareCanAddMetadata">
                <div class="text-wrap">Add Metadata</div>
            </mat-checkbox>
        </div>
        <div class="checkbox">
            <mat-checkbox [(ngModel)]="shareCanReshare">
                <div class="text-wrap">Reshare</div>
            </mat-checkbox>
        </div>
    </div>
</mat-dialog-content>

<mat-dialog-actions align="end">
    <button
        mat-button
        class="smx-secondary-button"
        mat-dialog-close
        cdkFocusInitial
    >
        Cancel
    </button>
    <button
        mat-button
        class="smx-primary-button ml-2"
        (click)="saveShare()"
        [disabled]="shareSaveDisabled || isSavingShareChanges"
    >
        Share
    </button>
</mat-dialog-actions>
