import {Component, OnInit, ViewChild, ElementRef} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {
    CasesListViewModel,
    CategoryViewModel,
    DocumentCaseItemViewModel,
    DocumentDetailsViewModel,
    DocumentMetadataViewModel,
    DocumentTagViewModel,
    SecuraMaxApiService,
    ShareViewModel,
    TagTypeViewModel,
    TagViewModel
} from 'src/app/services/api/securamaxapi.service';
import {UserSelectModel} from 'src/app/models/user-select.model';
import {TagSelectViewModel} from 'src/app/models/tag-select-view-model.model';
import {ApiService} from 'src/app/services/api.service';
import {SnackbarService} from 'src/app/services/snackbar.service';
import {MatDialog} from '@angular/material/dialog';
import {CreateCaseDialogComponent} from './create-case/create-case-dialog/create-case-dialog.component';
import * as moment from 'moment';
import {ConfirmDialogComponent} from 'src/app/shared/components/confirm-dialog/confirm-dialog.component';
import {ShareModifyDialogComponent} from './share-modify-dialog/share-modify-dialog.component';
import {Location, ViewportScroller} from '@angular/common';
import {TitleService} from 'src/app/services/title.service';
import {Observable} from 'rxjs-compat/Observable';
import {UserSelectClearService} from 'src/app/services/user/user-select-clear.service';
import {UserService} from 'src/app//services/user/user.service';
import {documentStatus} from 'src/app/shared/helpers/document-status-const';

export enum shareUserType {
    exisitingUser = 0,
    newUser = 1
}

@Component({
    selector: 'app-document-details',
    templateUrl: './document-details.component.html',
    styleUrls: ['./document-details.component.scss']
})
export class DocumentDetailsComponent implements OnInit {
    isUnlimitedPlanUser: boolean = false;
    documentStatus: typeof documentStatus = documentStatus;
    loading: boolean = true;
    document: DocumentDetailsViewModel;
    requestedFromArchive: boolean = false;
    isRequestRetrieve: boolean = false;
    videoSrcUrl: string = null;
    imageSrcUrl: string = null;
    shareSelect: UserSelectModel = new UserSelectModel();        // Contains the user selected for sharing the video.
    shareUserType: shareUserType = 0;
    newShareUserInput: string = "";
    shareUserSelectName: string = "shareUserSelect";
    shareMinDate: Date = new Date();
    shareDate: Date;
    shareTime: string;
    shareCanDownload: boolean = false;
    shareCanAddMetadata: boolean = false;
    shareCanReshare: boolean = false;
    shares: ShareViewModel[] = [];
    shareSubject?: string = '';
    shareSaveDisabled = true;
    isSavingShareChanges = false;

    isRequestDelete: boolean = false;
    requestDeleteSubmitClicked = false;
    deleteRequestReason: string;
    isReviewDeletionRequest: boolean;

    // Fields for editing metadata. We create separate vars so they aren't immediately changed in the 'Details' section.
    editMetaData: boolean = false;
    isSavingMetadata: boolean = false;
    titleToEdit: string = "";
    titleToEditError: string = null;
    descriptionToEdit: string = "";
    importantToEdit: boolean;
    ownerSelect: UserSelectModel = new UserSelectModel();   // Contains the user that selected when changing the owner.

    categoryList: CategoryViewModel[] = [];                 // Contains the list of all categories for tenant.
    selectedCategory: CategoryViewModel = new CategoryViewModel();   // Used for storing the category selected via the dropdown to be added to the doc.
    categoryEditList: CategoryViewModel[] = [];             // List used when editing categories on the document.

    tagsList: TagViewModel[] = [];                          // Contains the list of all tags.
    selectedTag: TagViewModel = new TagViewModel();         // Used for storing the tag selected via the dropdown to be added to the doc..
    tagsEditList: TagSelectViewModel[] = []                 // List of tags that are part of the document..

    caseListItem: CasesListViewModel = new CasesListViewModel();  // Used by app-case-select directive for adding/searching cases.
    caseEditList: CasesListViewModel[] = [];                // List used when editing the cases on the document.

    emailRegEx = new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);

    sharePanelOpen: boolean = false;
    showShare: boolean = false;
    showRedactions: boolean = false;
    showViewerLink: boolean = false;
    docInOpenCaseWithRetentionList: DocumentCaseItemViewModel[];

    isWindows: boolean = navigator.userAgent.indexOf("Win") != -1;


    daterangepicker_start: string;
    daterangepicker_end: string;


    constructor(private apiService: SecuraMaxApiService,
                private titleService: TitleService,
                private route: ActivatedRoute,
                private api: ApiService,
                private toastr: SnackbarService,
                public createCaseDialog: MatDialog,
                private cancelShareDialog: MatDialog,
                private modifyShareDialog: MatDialog,
                private location: Location,
                private viewPortScroller: ViewportScroller,
                private userSelectClearService: UserSelectClearService,
                private userService: UserService) {
    }

    private isNullOrEmpty(item: string) {
        if (item && item.length > 0) {
            return true;
        }
        return false;
    }

    ngOnInit(): void {

        if (this.userService.isUnlimitedPlan) {
            this.isUnlimitedPlanUser = true;
        }

        this.route.paramMap.subscribe((parammap) => {
            this.loading = true;
            this.document = null;
            this.videoSrcUrl = null;
            this.imageSrcUrl = null;
            this.shares = [];
            let id = parammap.get("id");
            this.apiService.documents_Get(id).toPromise().then(data => {
                this.document = data;
                this.showRedactions = this.document.redactions.length > 0;
                this.titleService.setTitle('Document Details');
                this.docInOpenCaseWithRetentionList = data.documentCaseItemList.filter(c => c.status === 1 && c.retention > 0);
                if (this.document.versions?.length > 0) {
                    this.videoSrcUrl = this.document.versions.find(x =>
                        x.videoFormat === "mp4"
                    )?.url;
                    this.imageSrcUrl = this.document.versions.find(x =>
                        x.videoFormat === "image/jpeg"
                    )?.url;
                } else if (this.document.parentId != null && this.document.parentId.length > 0) {
                    //try to load download url and set to video source
                    this.api.downloadDocument(this.document.id).toPromise().then((url) => {
                        this.videoSrcUrl = url;
                    });
                }
                this.loading = false;
            }, (err) => {
                this.toastr.error("An error occurred while getting the document.");
            });

            this.apiService.share_GetAll(id).toPromise().then(data => {
                this.shares = data;
            }, (err) => {
                this.toastr.error("An error occurred while getting document shares.");
            });
        });
    }

    clearShareInputs() {
        this.shareUserType = shareUserType.exisitingUser;
        this.userSelectClearService.announceUserClear(this.shareUserSelectName);
        this.shareSelect.associatedUser = '';
        this.shareSelect.associatedUserEmail = '';
        this.shareSelect.associatedUserId = null;
        this.newShareUserInput = '';
        this.shareDate = undefined;
        this.shareTime = undefined;
        this.shareCanDownload = false;
        this.shareCanAddMetadata = false;
        this.shareCanReshare = false;
        this.shareSubject = '';
    }

    openCancelShareDialog(share: ShareViewModel) {
        const dialogRef = this.cancelShareDialog.open(ConfirmDialogComponent, {
            maxWidth: "400px",
            data: {
                title: "Are you sure?",
                message: `You are about to unshare this document with: ${share.displayName}`
            }
        });

        dialogRef.afterClosed().subscribe(dialogResult => {
            // if user pressed yes dialogResult will be true,
            // if he pressed no - it will be false
            if (dialogResult) {
                this.apiService.share_Cancel(share.id).subscribe(() => {
                    const index = this.shares.findIndex(x => x.id == share.id);
                    if (index != -1)
                        this.shares.splice(index, 1);
                }, (err) => {
                    this.toastr.error("An error occurred while cancelling the share.");
                });
            }
        });
    }

    openModifyShareDialog(share: ShareViewModel) {
        const dialogRef = this.modifyShareDialog.open(ShareModifyDialogComponent, {
            maxWidth: "800px",
            data: share
        });

        dialogRef.afterClosed().subscribe(dialogResult => {

            if (dialogResult) {
                this.apiService.share_Put(dialogResult).subscribe(result => {
                    this.toastr.success('Share successful.');

                    const index = this.shares.findIndex(x => x.id == share.id);
                    if (index != -1)
                        this.shares.splice(index, 1);

                    this.shares.push(dialogResult);
                }, (err) => {
                    this.toastr.error("An error occurred while sharing the document.");
                });
            }
        });
    }

    saveShare() {
        if (!this.validateShare())
            return;

        let shareToSave = new ShareViewModel();

        if (this.shareUserType == shareUserType.exisitingUser)
            shareToSave.email = this.shareSelect.associatedUserEmail;
        else
            shareToSave.email = this.newShareUserInput;

        let timeAsMoment = moment(this.shareTime, "HH:mm");
        let combinedExpires = moment(this.shareDate).set({
            hour: timeAsMoment.get('hour'),
            minute: timeAsMoment.get("minute"),
            second: 0,
            millisecond: 0
        });
        shareToSave.expires = combinedExpires.utc().toDate();
        shareToSave.canDownload = this.shareCanDownload;
        shareToSave.canAddMetaData = this.shareCanAddMetadata;
        shareToSave.canReshare = this.shareCanReshare;
        shareToSave.emailSubject = this.shareSubject.replace(/ /g, "") ? this.shareSubject : null;

        this.isSavingShareChanges = true;
        this.apiService.share_Post(this.document.id, 0, shareToSave).subscribe(data => {
            this.toastr.success('Share successful');
            if (data.email.includes('@')) {
                data.displayName = data.email; //Overwrite display name with email for shares as they are added, see #2492
            } else {
                data.displayName = this.shareSelect.associatedUserEmail; //if shared with existing user, data.email is just display name
            }
            var foundShare = this.shares.find(x => x.email === data.email);
            if (foundShare === undefined) {
                this.shares.push(data);
            } else {
                this.toastr.success('The existing share to ' + foundShare.email + ' was overwritten');
                this.shares[this.shares.indexOf(foundShare)] = data;
            }
            this.clearShareInputs();
            this.shareButtonAllowed();
            this.isSavingShareChanges = false;
        }, err => {
            if (err.response === "You cannot share a document with yourself.") {
                this.toastr.error(err.response);
            } else {
                this.toastr.error("An error occurred while sharing the document.");
            }

            this.isSavingShareChanges = false;
        });
    }

    shareButtonAllowed() {
        var that = this;
        that.shareSaveDisabled = false;

        if (this.shareUserType == 0) {
            if (Object.keys(this.shareSelect).length === 0) {
                that.shareSaveDisabled = true;
                return;
            }
        } else {
            if (this.newShareUserInput == 'undefined' || this.newShareUserInput === "") {
                that.shareSaveDisabled = true;
                return;
            } else if (!this.emailRegEx.test(this.newShareUserInput)) {
                that.shareSaveDisabled = true;
                return;
            }
        }

        if (this.shareDate == null) {
            that.shareSaveDisabled = true;
            return;
        }

        if (this.shareTime == null || this.shareTime === "") {
            that.shareSaveDisabled = true;
            return;
        }

        if (moment(this.shareDate).date() === moment().date()) {
            if (moment(this.shareTime, 'HH:mm').isBefore(moment())) {
                that.shareSaveDisabled = true;
                return;
            }
        }
        return;
    }

    /**
     * Validates all the form fields in the share form.
     * @returns True when all form fields are valid. Otherwise false.
     */
    validateShare() {
        if (this.shareUserType == 0) {
            if (Object.keys(this.shareSelect).length === 0) {
                this.toastr.error("User must be selected to share.")
                return false;
            }
        } else {
            if (this.newShareUserInput == 'undefined' || this.newShareUserInput === "") {
                this.toastr.error("Email address required for share.")

                return false;
            } else if (!this.emailRegEx.test(this.newShareUserInput)) {
                this.toastr.error("Valid email address required for share.");
                return false;
            }
        }

        if (this.shareDate == null) {
            this.toastr.error("Date required for share.");
            return false;
        }

        if (this.shareTime == null || this.shareTime === "") {
            this.toastr.error("Time required for share.");
            return false;
        }

        if (moment(this.shareDate).date() === moment().date()) {
            if (moment(this.shareTime, 'HH:mm').isBefore(moment())) {
                this.toastr.error("Time must be after now.");
                return false;
            }
        }

        return true;
    }

    /**
     * Downloads the document.
     */
    downloadDocument() {
        this.api.downloadDocument(this.document.id).subscribe(url => {
            window.location.href = url;
        });
    }

    streamDocument() {
        this.showViewerLink = true;

        this.api.downloadDocument(this.document.id).subscribe(url => {
            window.location.href = url.replace("https://", "pv-hdviewer://");
        });
    }

    getDownloadUrl(): Observable<string> {
        return this.api.downloadDocument(this.document.id);
    }

    /**
     * Downloads the audit report.
     */
    downloadAuditReport() {
        this.api.downloadDocumentAuditReport(this.document.id).subscribe(x => {
                this.toastr.success("Audit report downloaded. Check download location.");
            },
            (err) => {
                this.toastr.error("An error occurred while downloading audit report");
            });
    }

    requestDeleteSubmit() {
        this.isRequestDelete = true;
        this.requestDeleteSubmitClicked = true;
        if (this.document.permissions.canDelete && this.document.isDeletionLocked === false) {
            this.apiService.documentTask_PostDeletionRequest(
                this.document.id, this.deleteRequestReason
            ).subscribe(x => {
                //this.$state.go('documents.details', { id: this.document.Id }, { reload: true });
                //that.document.DeletionRequest = req;
                this.toastr.success('Successfully requested delete.');
                window.location.reload();
                this.isRequestDelete = false;
            }, (err) => {
                this.toastr.error("An error occurred when requesting deletion.");
                this.isRequestDelete = false;
                this.requestDeleteSubmitClicked = false;
            });
        }
    }

    denyDeleteRequeset() {
        this.isReviewDeletionRequest = false;

        if (this.document.permissions.canApproveDelete && this.document.isDeletionLocked === false) {
            this.apiService.documentTask_PostApproveDeletionRequest(
                this.document.deletionRequest.taskId, false
            ).subscribe(x => {
                this.document.isPendingDelete = false;
                delete this.document.deletionRequest;
                this.toastr.success('Successfully disapproved delete.');
            }, (err) => {
                this.toastr.error("An error occurred while approving deletion.");
            });
        }
    }

    approveDeleteRequeset() {
        this.isReviewDeletionRequest = false;
        this.document.isPendingDelete = true;
    }

    lockFromDeletion() {
        this.apiService.documents_PutLockFromDeletion(this.document.id.toString(), true).subscribe(() => {
            this.document.isDeletionLocked = true;
            this.document.deletionDate = null;
            this.isRequestDelete = false;
            this.isReviewDeletionRequest = false;
            this.toastr.success("Document is locked from deletion.");
        }, (err) => {
            this.toastr.error('Unable to lock at this time. Try again later.');
        });
    }

    unlockFromDeletion() {
        this.apiService.documents_PutLockFromDeletion(this.document.id.toString(), false).subscribe((updatedDeletionDate) => {
            this.document.isDeletionLocked = false;
            this.document.deletionDate = updatedDeletionDate;
            this.toastr.success("Document is unlocked for deletion.");
        }, (err) => {
            this.toastr.error('Unable to unlock at this time. Try again later.');
        });

    }

    /**
     * Shows the edit form for modifying the documents metadata.
     * We copy the document information to the temp variables so the
     * data change isn't reflected in the 'details' section.
     */
    editMetaDataClick() {
        this.editMetaData = !this.editMetaData;
        this.titleToEditError = null;
        this.titleToEdit = this.document.title;
        this.descriptionToEdit = this.document.description;
        this.ownerSelect.associatedUser = this.document.ownerName;
        this.ownerSelect.associatedUserId = this.document.ownerId;
        this.categoryEditList = Object.assign([], this.document.categories);
        this.caseEditList = Object.assign([], this.document.documentCaseItemList);
        this.importantToEdit = this.document.important;

        this.loadCategories().then(() => {
            this.viewPortScroller.scrollToAnchor('editMetaDataContainer');
        });
        this.loadTags();
        //this.scrollToEditMetadata();
    }

    /**
     * Loads the categories from the server. Only happens once on the first time
     * the 'Edit Metadata' button is clicked.
     */
    loadCategories() {
        return this.apiService.categories_GetAll().toPromise().then(data => {
            this.categoryList = data;
        }, (err) => {
            this.toastr.error("An error occurred while loading categories.");
        });
    }

    /**
     * Loads the tags from the server. Populates the 'tags' section of the
     * edit form with tags that exist on the document.
     */
    loadTags() {
        if (this.tagsList.length <= 0) {
            // Get all tags to show in the list. This will include tags that have
            // been deleted from the 'lookups' page but are still part of the document.
            this.apiService.tags_GetAll().subscribe(data => {
                this.tagsList = data;

                // Get each tag in the document and display it in the tags list.
                // We pass the tag.id to be sent back to the server incase the value is updated.
                // tag.value will display the value in the input field.
                this.document.tags.forEach(tag => {
                    var tagItem = this.tagsList.find(x => x.id == tag.tagId);
                    this.addTag(tagItem, tag.id, tag.value);
                })
                // Now only display active tags that haven't been deleted from the lookups page.
                this.apiService.tags_GetAllActiveTags().subscribe(data => {
                    this.tagsList = data;
                }, (err) => {
                    this.toastr.error("An error occurred while loading tags.");
                });
            }, (err) => {
                this.toastr.error("An error occurred while loading tags.");
            });
        }
    }

    isShareExpired(share: ShareViewModel) {
        return share.expires < new Date();
    }

    /**
     * Adds a category to the document.
     * @param category Category to add to document.
     */
    addCategory(category) {
        const index = this.categoryEditList.findIndex(x =>
            x.name == category.name
        );

        if (index == -1) {
            this.categoryEditList.push(category);
        }
    }

    /**
     * Removes a category from the document.
     * @param category Category to remove from document
     */
    removeCategory(category) {
        const index = this.categoryEditList.indexOf(category);

        if (index >= 0) {
            this.categoryEditList.splice(index, 1);
        }
    }

    /**
     * Adds a case to the document.
     */
    addCase() {
        const index = this.caseEditList.findIndex(x =>
            x.title == this.caseListItem.title
        );

        if (index == -1 && this.caseListItem.title != null) {
            this.caseEditList.push(this.caseListItem);
        }
    }

    /**
     * Removes a case from the document.
     * @param caseItem Removes a case from the document.
     */
    removeCase(caseItem) {
        const index = this.caseEditList.indexOf(caseItem);

        if (index >= 0) {
            this.caseEditList.splice(index, 1);
        }
    }

    /**
     * Adds a tag to the metadata form.
     * @param tag tag to be displayed in the list.
     * @param id  Used by existing tags on the doc to be sent back to server in case of updating value.
     * @param value Used by existing tags on the doc to display the value in the tag form.
     */
    addTag(tag, id?: number, value?: string) {
        let tagSelectVm = new TagSelectViewModel(tag, id, value);
        this.tagsEditList.push(tagSelectVm);
    }

    /**
     * Removes a tag from the list of tags.
     * @param index Index of the tag in the list to be removed.
     */
    removeTag(index) {
        if (index >= 0) {
            this.tagsEditList.splice(index, 1);
        }
    }

    /**
     * Opens a dialog box to create a new case.
     */
    openCreateCaseDialog() {
        const dialogRef = this.createCaseDialog.open(CreateCaseDialogComponent, {
            height: "45%",
            width: "30%"
        });

        dialogRef.afterClosed().subscribe(data => {
            // Auto add the case to the list once successfully created.
            if (data) {
                this.apiService.cases_Search(data.title, 1).subscribe(caseItem => {
                    if (caseItem.length > 0) {
                        this.caseListItem = caseItem[0];
                        this.addCase();
                    }
                }, (err) => {
                    this.toastr.error("An error occurred while searching for cases.");
                });
            }
        });
    }

    /**
     * Saves the metadata changes to the server.
     *
     * Copies the data from the temp vars to the correct object to be
     * sent to the server.
     */
    onSaveMetadata() {
        let error = this.validateMetadataTitle();
        if (error) {
            this.titleToEditError = error;
            return;
        }

        let dataToBeSaved = new DocumentMetadataViewModel();
        dataToBeSaved.documentId = this.document.id;
        dataToBeSaved.title = this.titleToEdit;
        dataToBeSaved.description = this.descriptionToEdit
        dataToBeSaved.ownerId = this.ownerSelect.associatedUserId;
        dataToBeSaved.categories = this.categoryEditList;
        dataToBeSaved.cases = this.caseEditList;
        dataToBeSaved.important = this.importantToEdit;

        dataToBeSaved.tags = this.tagsEditList.map((x) => {
            let item = new DocumentTagViewModel();
            item.id = x.id;
            item.tagId = x.tagId;
            item.tagType = new TagTypeViewModel()
            item.tagType.id = x.tagId;
            item.tagType.name = x.name;
            item.value = x.value;
            return item;
        });

        let documentMetadataViewModelList: DocumentMetadataViewModel[] = [];
        documentMetadataViewModelList.push(dataToBeSaved);

        this.isSavingMetadata = true;
        this.apiService.metadata_Put(documentMetadataViewModelList).subscribe(result => {
            this.editMetaData = !this.editMetaData;

            let id = this.route.snapshot.paramMap.get("id")
            this.apiService.documents_Get(id).subscribe(data => {
                this.document = data;
                this.isSavingMetadata = false;
            });
        }, (err) => {
            this.toastr.error("An error occurred while saving the metadata.");
            this.isSavingMetadata = false;
        });
    }

    /**
     * Validates the documents title agains the 'required' and 'regex' fields.
     * */
    validateMetadataTitle() {
        if (this.document.requireDocumentTitle) {
            if (!this.titleToEdit)
                return "Title is Required";

            if (this.document.documentTitleRegex) {
                // Do a regex check on the title.
                var patt = new RegExp(this.document.documentTitleRegex);
                var isValid = patt.test(this.titleToEdit);

                if (!isValid)
                    return "Title does not match the required format."
            }
        }

        return;
    }

    /*
    * Sends document to be rehydrated
    * */
    requestArchiveRetrieval() {
        this.requestedFromArchive = true;
        this.apiService.documents_UnArchiveDocument(this.document.id).subscribe((data) => {
            this.toastr.success("Document requested from archive. Please allow up to 24 hours for completion. An email will be sent to you when complete.");
        }, (err) => {
            this.toastr.error(err.message);
        });
        //Do API Call here
        //this.apiService.documents_RequestFromArchive(this.document.id).subcribe((data) =>
        //{
        //  this.toastr.success("Document requested from archive. Please allow up to 24 hours for completion.");
        //  Reload Page? Change statuses so page looks the same now as after a refresh? Something else?
        //});
    }

    backToSearchClicked() {
        this.location.back();
    }

    daterangepicker_change($event: any) {
        this.shareDate = moment(this.daterangepicker_start).toDate();
        this.shareTime = moment(this.daterangepicker_start).format('HH:mm');
        this.shareButtonAllowed()
    }
}
