import { inject } from '@angular/core';
import { CanMatchFn } from '@angular/router';
import { FeatureFlagStorageService } from '../services/feature-flags-storage.service';

export const featureFlagGuard = (feature: string): CanMatchFn => {
    return (route, segments) => {
        const featureFlagStorageService = inject(FeatureFlagStorageService);
        return featureFlagStorageService.isFeatureEnabled(feature);
    };
};

